<template>
	<v-card
		tile
		elevation="0"
		style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
	>
		<div
			:style="`padding: .5rem; background: ${isDark ? '#121212' : '#ffffff'};`"
		>
			<v-tooltip top>
				<template v-slot:activator="{ on }">
					<v-btn v-on="on" icon @click="handleRefresh">
						<v-icon>refresh</v-icon>
					</v-btn>
				</template>
				{{ $t('tooltip.refresh') }}
			</v-tooltip>
		</div>

		<v-divider></v-divider>

		<div
			:style="`position: absolute; top: 53px; right: 0; bottom: 0; left: 0; overflow: auto; background: ${
				isDark ? '#424242' : '#eeeeee'
			};`"
		>
			<v-container fluid fill-height style="padding: 0">
				<v-layout>
					<v-flex>
						<v-container>
							<v-row>
								<v-col>
									<v-card tile elevation="1">
										<v-container>
											<v-row>
												<v-col>
													<JobPicker
														v-model="filters.job_id"
														:jobs="jobs"
														:label="$t('label.job')"
														hide-inactive-teams
														hide-inactive-departments
														hide-draft-jobs
														:clearable="true"
														dense
														@input="handleFilterChange"
														multiple
													></JobPicker>
												</v-col>
												<v-col v-if="tab !== 2">
													<UserPicker
														v-model="filters.owner"
														:users="users.filter(item => item.active)"
														:label="$t('label.owner')"
														multiple
														@input="handleFilterChange"
														dense
													></UserPicker>
												</v-col>
												<v-col v-if="tab === 2">
													<DatePicker
														v-model="datePickerDate"
														@input="handleDatePickerInput"
														no-title
														reactive
														dense
														range
														:label="$t('label.date-range')"
													></DatePicker>
												</v-col>
												<v-col v-if="tab === 2">
													<UserPicker
														v-model="filters.interviewer"
														:users="users.filter(item => item.active)"
														:label="$t('label.interviewer')"
														multiple
														@input="handleFilterChange"
														dense
													></UserPicker>
												</v-col>
											</v-row>
										</v-container>
									</v-card>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-card tile elevation="1">
										<v-card-title class="pa-0">
											<v-toolbar flat>
												<v-tabs
													v-model="tab"
													align-with-title
													slider-size="3"
													slider-color="primary"
												>
													<v-tab class="report-tab">
														{{ $t('tab.current-situation').toLocaleUpperCase("TR-tr") }}
													</v-tab>
													<v-tab class="report-tab" v-if="userRole >= 666">
														{{ $t('tab.average-durations').toLocaleUpperCase("TR-tr") }}
													</v-tab>
													<v-tab class="report-tab" v-if="userRole >= 666">
														{{ $t('tab.interviewers').toLocaleUpperCase("TR-tr") }}
													</v-tab>
												</v-tabs>
											</v-toolbar>
										</v-card-title>
										<v-divider></v-divider>
										<v-card-text>
											<v-tabs-items v-model="tab">
												<v-tab-item>
													<CurrentSituation
														ref="currentSituation"
													></CurrentSituation>
												</v-tab-item>
												<v-tab-item>
													<AverageDurations
														ref="averageDurations"
													></AverageDurations>
												</v-tab-item>
												<v-tab-item>
													<Interviewers
														ref="interviewers"
														:filters="filters"
													></Interviewers>
												</v-tab-item>
											</v-tabs-items>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</v-container>
					</v-flex>
				</v-layout>
			</v-container>
		</div>
	</v-card>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import JobPicker from '@/components/shared/jobPicker/JobPicker';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import DatePicker from '@/components/shared/datePicker/DatePicker';
import CurrentSituation from './currentSituation/CurrentSituation';
import AverageDurations from './averageDurations/AverageDurations';
import Interviewers from './interviewers/Interviewers';

export default {
	name: 'AtAGlance',
	components: {
		JobPicker,
		UserPicker,
		DatePicker,
		CurrentSituation,
		AverageDurations,
		Interviewers,
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
			lang: state => state.currentUser.lang,
			userRole: state => state.currentUser.userRole,
			jobs: state => state.jobs.items,
			users: state => state.users.items,
			currentLang: state => state.currentUser.lang,
		}),
	},
	data() {
		const { owner, job_id, interviewer, min_date, max_date } =
			this.$route.query;

		const end_date = new Date();
		end_date.setDate(end_date.getDate() - 1);
		const start_date = new Date(end_date);
		start_date.setDate(start_date.getDate() - 90);

		const max_date_formatted = max_date
			? max_date
			: format(end_date, 'yyyy-MM-dd');
		const min_date_formatted = min_date
			? min_date
			: format(start_date, 'yyyy-MM-dd');

		const datePickerDate = [min_date_formatted, max_date_formatted];

		return {
			format,
			filters: {
				job_id: job_id ? job_id.split(',').map(item => parseInt(item, 10)) : [],
				owner: owner ? owner.split(',').map(item => parseInt(item, 10)) : [],
				interviewer: interviewer
					? interviewer.split(',').map(item => parseInt(item, 10))
					: [],
				max_date: max_date_formatted,
				min_date: min_date_formatted,
			},
			datePickerDate,
			tab: 0,
		};
	},
	methods: {
		handleFilterChange() {
			const { job_id, owner, interviewer } = this.filters;
			const query = {
				job_id: job_id.length > 0 ? job_id.join(',') : undefined,
				owner: owner.length > 0 ? owner.join(',') : undefined,
				interviewer: interviewer.length > 0 ? interviewer.join(',') : undefined,
			};
			this.$router.push({
				path: `/${this.currentLang}/reports/at-a-glance`,
				query,
			});
		},
		handleDatePickerInput(dates) {
			if (dates && dates.length === 2) {
				const { query } = this.$route;
				const { currentLang } = this;
				(this.filters = {
					...this.filters,
					min_date: dates[0],
					max_date: dates[1],
				}),
					this.$router.push({
						path: `/${currentLang}/reports/at-a-glance`,
						query: {
							...query,
							min_date: dates[0],
							max_date: dates[1],
						},
					});
			}
		},
		handleRefresh() {
			const { tab } = this;

			if (tab === 0) {
				this.$refs.currentSituation.handleRefresh();
			} else if (tab === 1) {
				this.$refs.averageDurations.handleRefresh();
			} else if (tab === 2) {
				this.$refs.interviewers.handleRefresh();
			}
		},
	},
};
</script>

<style lang="scss">
.talentics-picker {
	&.theme--dark.v-card {
		background: #121212;
	}

	.theme--dark.v-picker__body {
		background: #121212;
	}
}

.report-tab {
	text-transform: none !important;
}
</style>
