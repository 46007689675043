<template>
	<div>
		<v-dialog
			v-model="dialogVisible"
			max-width="400"
			@click:outside="handleClickOutside"
			@keydown.esc="handleClickOutside"
			persistent
		>
			<v-card>
				<ValidationObserver ref="pppa_form" tag="form" @submit="handleSubmit">
					<v-container>
						<v-row>
							<v-col>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-text-field
										v-model="predefinedPositionPresetEditable.name"
										outlined
										:label="$t('label.name')"
										:error-messages="errors"
										hide-details
										dense
									></v-text-field>
								</ValidationProvider>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.status') }}:
										{{
											predefinedPositionPresetEditable.active
												? $t('label.active')
												: $t('label.inactive')
										}}
									</legend>
									<v-switch
										disabled
										v-model="predefinedPositionPresetEditable.active"
										color="success"
										inset
										hide-details
										dense
										class="v-input--template-switch"
									></v-switch>
								</fieldset>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<label class="v-label v-label--active" style="font-size: 12px">
									{{ $t('text.new-position-you-will-be-redirected') }}
								</label>
							</v-col>
						</v-row>
					</v-container>
				</ValidationObserver>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="handleClose">{{
						$t('button.close')
					}}</v-btn>
					<v-btn color="primary" @click="handleSubmit">{{
						$t('button.save')
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	name: 'PredefinedPositionPresetAttrs',
	components: {
		ValidationObserver,
		ValidationProvider,
	},
	props: {
		predefinedPositionPreset: {
			type: Object,
			required: false,
			default() {
				return {
					active: true,
					name: '',
				};
			},
		},
		dialogVisible: {
			type: Boolean,
			required: true,
		},
		onClose: {
			type: Function,
			required: true,
		},
		onSubmit: {
			type: Function,
			required: false,
		},
		teamId: {
			type: Number,
			required: false,
		},
	},
	data() {
		const { predefinedPositionPreset } = this;
		return {
			predefinedPositionPresetEditable: JSON.parse(
				JSON.stringify(predefinedPositionPreset),
			),
		};
	},
	methods: {
		handleClose() {
			this.onClose();
		},
		async handleSubmit(evt) {
			if (evt) {
				evt.preventDefault();
			}
			const isValid = await this.$refs.pppa_form.validate();
			if (isValid) {
				const { predefinedPositionPresetEditable } = this;
				this.$store
					.dispatch('predefinedPositionPresets/post', {
						items: [{ ...predefinedPositionPresetEditable }],
					})
					.then(({ data: { items } }) => {
						const predefinedPositionPresetId = items[0].id;

						this.$store
							.dispatch('predefinedPositions/post', {
								items: [
									{
										team_id: this.teamId,
										predefined_position_preset_id: predefinedPositionPresetId,
										name: predefinedPositionPresetEditable.name,
										active: true,
									},
								],
							})
							.then(({ data: { items } }) => {
								const createdPositionsId = items[0].id;
								this.onSubmit(this.teamId, createdPositionsId);
							});
					});
			}
		},
		handleClickOutside(evt) {
			evt.preventDefault();
		},
	},
	watch: {
		predefinedPositionPreset(newVal) {
			this.predefinedPositionPresetEditable = { ...newVal };
		},
	},
};
</script>
