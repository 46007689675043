var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":true,"persistent":"","scrollable":"","transition":"dialog-bottom-transition","max-width":"600"},on:{"click:outside":_vm.handleClickOutside,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.handleClickOutside.apply(null, arguments)}}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":`${_vm.isDark ? '' : 'grey lighten-3'}`,"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.reportTemplate.label))]),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"padding":"0"}},[_c('ValidationObserver',{ref:"exportable_reports_form",attrs:{"tag":"form","id":"exportable_reports_form"},on:{"submit":_vm.handleSubmit}},[_c('v-container',[(_vm.reportTemplate.is_date_picker_visible)?_c('v-row',[_c('v-col',[_c('DatePicker',{attrs:{"no-title":"","reactive":"","dense":"","range":"","label":_vm.$t('label.date-range'),"disabled":_vm.isGenerated},model:{value:(_vm.form.datePickerDate),callback:function ($$v) {_vm.$set(_vm.form, "datePickerDate", $$v)},expression:"form.datePickerDate"}})],1)],1):_vm._e(),(_vm.reportTemplate.is_job_picker_visible)?_c('v-row',[_c('v-col',[_c('JobPicker',{attrs:{"multiple":"","jobs":_vm.jobs,"label":`${_vm.$t('label.jobs')} ${
									_vm.reportTemplate.predefined_type ===
									'position-based-applications'
										? '*'
										: ''
								}`,"hide-inactive-teams":"","hide-inactive-departments":"","hide-draft-jobs":"","dense":"","disabled":_vm.isGenerated,"rules":_vm.reportTemplate.predefined_type ===
									'position-based-applications'
										? 'required'
										: ''},model:{value:(_vm.form.job_id),callback:function ($$v) {_vm.$set(_vm.form, "job_id", $$v)},expression:"form.job_id"}})],1)],1):_vm._e(),(_vm.reportTemplate.is_owner_picker_visible)?_c('v-row',[_c('v-col',[_c('UserPicker',{attrs:{"users":_vm.users.filter(item => item.active),"label":_vm.$t('label.hiring-manager'),"multiple":"","dense":"","disabled":_vm.isGenerated},model:{value:(_vm.form.hiring_manager),callback:function ($$v) {_vm.$set(_vm.form, "hiring_manager", $$v)},expression:"form.hiring_manager"}})],1)],1):_vm._e(),(_vm.reportTemplate.is_interviewer_picker_visible)?_c('v-row',[_c('v-col',[_c('UserPicker',{attrs:{"users":_vm.users.filter(item => item.active),"label":_vm.$t('label.interviewer'),"multiple":"","dense":"","disabled":_vm.isGenerated},model:{value:(_vm.form.interviewer),callback:function ($$v) {_vm.$set(_vm.form, "interviewer", $$v)},expression:"form.interviewer"}})],1)],1):_vm._e()],1)],1)],1),_c('v-divider'),(_vm.isGenerated)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")]),_c('v-btn',{attrs:{"color":"primary","href":_vm.generatedUrl,"_target":"blank"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("download")]),_vm._v(" "+_vm._s(_vm.$t('button.download-xlsx'))+" ")],1)],1):_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.handleClose}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('button.export'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }