<template>
	<div>
		<v-container>
			<v-row>
				<v-col cols="12" md="8">
					<ChartBar :chartdata="chartDataGetHiredDurations"></ChartBar>
				</v-col>
				<v-col cols="12" md="4" align-self="center">
					<v-alert border="left" colored-border type="info" elevation="2">
						{{
							$t('text.average-duration-report-info-1', {
								dayCount: averageGetHiredDays,
							})
						}}
					</v-alert>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="4" align-self="center">
					<v-alert border="left" colored-border type="info" elevation="2">
						{{ $t('text.average-duration-report-info-2') }}
					</v-alert>
				</v-col>
				<v-col cols="12" md="8">
					<ChartBar
						:filterZeroValues="true"
						:chartdata="chartDataInterviewDurations"
					></ChartBar>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="8">
					<ChartBar
						:filterZeroValues="true"
						:chartdata="chartDataLeadDurations"
					></ChartBar>
				</v-col>
				<v-col cols="12" md="4" align-self="center">
					<v-alert border="left" colored-border type="info" elevation="2">
						{{ $t('text.average-duration-report-info-3') }}
					</v-alert>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import api from '@/api';
import ChartBar from '@/components/shared/chartBar/ChartBar';

export default {
	name: 'AverageDurations',
	components: {
		ChartBar,
	},
	data() {
		return {
			chartDataGetHiredDurations: {
				labels: [],
				datasets: [],
			},
			chartDataInterviewDurations: {
				labels: [],
				datasets: [],
			},
			chartDataLeadDurations: {
				labels: [],
				datasets: [],
			},
			averageGetHiredDays: 0,
		};
	},
	methods: {
		handleGetChartDataGetHiredDurations() {
			const { query } = this.$route;
			api.reports.durations.getHired.get({ ...query }).then(response => {
				const { labels, datasets, average_duration } = response.data;

				const filteredLabels = [];
				const filteredDatasets = datasets.map(dataset => ({
					...dataset,
					data: [],
				}));

				labels.forEach((label, index) => {
					let hasNonZero = false;

					// eslint-disable-next-line no-unused-vars
					datasets.forEach((dataset, datasetIndex) => {
						if (dataset.data[index] !== 0) {
							hasNonZero = true;
						}
					});

					if (hasNonZero) {
						filteredLabels.push(label);

						datasets.forEach((dataset, datasetIndex) => {
							filteredDatasets[datasetIndex].data.push(dataset.data[index]);
						});
					}
				});

				this.chartDataGetHiredDurations = {
					labels: filteredLabels,
					datasets: filteredDatasets,
				};
				this.averageGetHiredDays = average_duration;
			});
		},
		handleGetChartDataInterviewDurations() {
			const { query } = this.$route;
			api.reports.durations.interview.get({ ...query }).then(response => {
				const { labels, datasets } = response.data;

				const filteredLabels = [];
				const filteredDatasets = datasets.map(dataset => ({
					...dataset,
					data: [],
				}));

				labels.forEach((label, index) => {
					let hasNonZero = false;

					// eslint-disable-next-line no-unused-vars
					datasets.forEach((dataset, datasetIndex) => {
						if (dataset.data[index] !== 0) {
							hasNonZero = true;
						}
					});

					if (hasNonZero) {
						filteredLabels.push(this.$t(`pipeline.steps.${label}`));

						datasets.forEach((dataset, datasetIndex) => {
							filteredDatasets[datasetIndex].data.push(dataset.data[index]);
						});
					}
				});

				this.chartDataInterviewDurations = {
					labels: filteredLabels,
					datasets: filteredDatasets,
				};
			});
		},
		handleGetChartDataLeadDurations() {
			const { query } = this.$route;
			api.reports.durations.lead.get({ ...query }).then(response => {
				const { labels, datasets } = response.data;

				const filteredLabels = [];
				const filteredDatasets = datasets.map(dataset => ({
					...dataset,
					data: [],
				}));

				labels.forEach((label, index) => {
					let hasNonZero = false;

					// eslint-disable-next-line no-unused-vars
					datasets.forEach((dataset, datasetIndex) => {
						if (dataset.data[index] !== 0) {
							hasNonZero = true;
						}
					});

					if (hasNonZero) {
						filteredLabels.push(this.$t(`pipeline.steps.${label}`));

						datasets.forEach((dataset, datasetIndex) => {
							filteredDatasets[datasetIndex].data.push(dataset.data[index]);
						});
					}
				});

				this.chartDataLeadDurations = {
					labels: filteredLabels,
					datasets: filteredDatasets,
				};
			});
		},
	},
	mounted() {
		this.handleGetChartDataGetHiredDurations();
		this.handleGetChartDataInterviewDurations();
		this.handleGetChartDataLeadDurations();
	},
	watch: {
		'$route.query': {
			handler: function (newVal, oldVal) {
				if (
					oldVal === undefined ||
					newVal.job_id !== oldVal.job_id ||
					newVal.owner !== oldVal.owner
				) {
					this.handleGetChartDataGetHiredDurations();
					this.handleGetChartDataInterviewDurations();
					this.handleGetChartDataLeadDurations();
				}
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>
