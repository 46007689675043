// @ts-nocheck
import Vue from 'vue';
import Router from 'vue-router';
// import store from '@/store';
import Lang from '@/components/routes/lang/Lang';
// Jobs
import Jobs from '@/components/routes/jobs/Jobs';
import Job from '@/components/routes/jobs/job/Job';
import JobRequisition from '@/components/routes/jobs/jobRequisition/JobRequisition';
// Candidates
import Candidates from '@/components/routes/candidates/Candidates';
import Candidate from '@/components/routes/candidates/candidate/Candidate';
import NewCandidate from '@/components/routes/candidates/newCandidate/NewCandidate';
import OfferRequisition from '@/components/routes/candidates/candidate/offerRequisition/OfferRequisition';
import BulkCandidateImport from '@/components/routes/candidates/bulkCandidateImport/BulkCandidateImport';
// Settings
import Settings from '@/components/routes/settings/Settings';
import MyAccount from '@/components/routes/settings/myAccount/MyAccount';
import Users from '@/components/routes/settings/users/Users';
import User from '@/components/routes/settings/users/user/User';
import AddUser from '@/components/routes/settings/users/addUser/AddUser';
import Company from '@/components/routes/settings/company/Company';
import EmailTemplates from '@/components/routes/settings/emailTemplates/EmailTemplates';
import EmailLayouts from '@/components/routes/settings/emailLayouts/EmailLayouts';
import FeedbackTemplates from '@/components/routes/settings/feedbackTemplates/FeedbackTemplates';
import ApprovalTemplates from '@/components/routes/settings/approvalTemplates/ApprovalTemplates';
import JobSite from '@/components/routes/settings/jobSite/JobSite';
import ResourceUsage from '@/components/routes/settings/resourceUsage/ResourceUsage';
import ScorecardTemplates from '@/components/routes/settings/scorecardTemplates/ScorecardTemplates';
import InstantBooking from '@/components/routes/settings/instantBooking/InstantBooking';
import AddEventType from '@/components/routes/settings/instantBooking/addEventType/AddEventType';
import Tags from '@/components/routes/settings/tags/Tags';
import FormTemplates from '@/components/routes/settings/formTemplates/FormTemplates';
import RequisitionFormTemplates from '@/components/routes/settings/requisitionFormTemplates/RequisitionFormTemplates';
import RequisitionApprovalFlowTemplates from '@/components/routes/settings/requisitionApprovalFlowTemplates/RequisitionApprovalFlowTemplates';
import OfferTemplates from '@/components/routes/settings/offerTemplates/OfferTemplates';
import Rulesets from '@/components/routes/settings/rulesets/Rulesets';
// import UsersIndex from '@/components/routes/settings/users/index/UsersIndex';
// import EditUser from '@/components/routes/settings/users/edit/EditUser';
// import Company from '@/components/routes/settings/company/Company';
// Reports
import Reports from '@/components/routes/reports/Reports';
import DailySnapshots from '@/components/routes/reports/dailySnapshots/DailySnapshots';
import AtAGlance from '@/components/routes/reports/atAGlance/AtAGlance';
import ExportReport from '@/components/routes/reports/exportReport/ExportReport';
import ExportReportDialog from '@/components/routes/reports/exportReport/exportReportDialog/ExportReportDialog';
import Auth from '@/components/routes/auth/Auth';
import EmailLink from '@/components/routes/auth/emailLink/EmailLink';
import HireeInfo from '@/components/routes/candidates/candidate/overview/hireeInfo/HireeInfo';

import Maintenance from '../components/routes/maintenance/Maintenance';
import MaintenanceView from '../components/routes/maintenance/maintenanceView';

import store from '../store';

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (!err.name.includes('NavigationDuplicated')) throw err;
	});
};

let router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/auth',
			component: Auth,
			children: [
				{
					path: 'email-link',
					component: EmailLink,
				},
			],
		},
		{
			path: '/maintenance',
			component: Maintenance,
			children: [
				{
					path: '',
					component: MaintenanceView,
				},
			],
		},
		{
			path: '/:lang',
			component: Lang,
			children: [
				{
					path: '',
					component: Candidates,
					name: 'Home',
				},
				{
					path: 'jobs',
					component: Jobs,
					children: [
						{
							path: 'edit/:jobId',
							component: Job,
							children: [
								{
									path: 'requisitions/:requisitionId',
									component: JobRequisition,
								},
							],
						},
						{
							path: 'new',
							component: Job,
						},
						{
							path: 'new-requisition',
							component: JobRequisition,
						},
					],
				},
				{
					path: 'candidates',
					component: Candidates,
					children: [
						{
							path: 'edit/:candidateId',
							component: Candidate,
							children: [
								{
									path: 'requisitions/:requisitionId',
									component: OfferRequisition,
								},
								{
									path: 'hiree-info/:hireeInfoId',
									component: HireeInfo,
								},
							],
						},
						{
							path: 'new',
							component: NewCandidate,
						},
						{
							path: 'bulk-import',
							component: BulkCandidateImport,
						},
					],
				},
				{
					path: 'settings',
					component: Settings,
					children: [
						{
							path: 'users',
							name: 'users',
							component: Users,
							children: [
								{
									path: 'edit/:userId',
									component: User,
								},
								{
									path: 'new',
									component: AddUser,
								},
							],
						},
						{
							path: 'my-account',
							name: 'my-account',
							component: MyAccount,
						},
						{
							path: 'company',
							name: 'company',
							component: Company,
						},
						{
							path: 'email-templates',
							name: 'email-templates',
							component: EmailTemplates,
						},
						{
							path: 'email-layouts',
							name: 'email-layouts',
							component: EmailLayouts,
						},
						{
							path: 'feedback-templates',
							name: 'feedback-templates',
							component: FeedbackTemplates,
						},
						{
							path: 'form-templates',
							name: 'form-templates',
							component: FormTemplates,
						},
						{
							path: 'scorecard-templates',
							name: 'scorecard-templates',
							component: ScorecardTemplates,
						},
						{
							path: 'approval-templates',
							name: 'approval-templates',
							component: ApprovalTemplates,
						},
						{
							path: 'job-site',
							name: 'job-site',
							component: JobSite,
						},
						{
							path: 'instant-booking',
							name: 'instant-booking',
							component: InstantBooking,
							children: [
								{
									path: 'edit/:eventId',
									component: AddEventType,
								},
								{
									path: 'new',
									component: AddEventType,
								},
							],
						},
						{
							path: 'tags',
							name: 'tags',
							component: Tags,
						},
						{
							path: 'requisition-form-templates',
							name: 'requisition-form-templates',
							component: RequisitionFormTemplates,
						},
						{
							path: 'requisition-approval-flow-templates',
							name: 'requisition-approval-flow-templates',
							component: RequisitionApprovalFlowTemplates,
						},
						{
							path: 'resource-usage',
							name: 'resource-usage',
							component: ResourceUsage,
						},
						{
							path: 'offer-templates',
							name: 'offer-templates',
							component: OfferTemplates,
						},
						{
							path: 'rulesets',
							name: 'rulesets',
							component: Rulesets,
						},
					],
				},
				{
					path: 'reports',
					component: Reports,
					children: [
						{
							path: 'daily-snapshots',
							component: DailySnapshots,
						},
						{
							path: 'at-a-glance',
							component: AtAGlance,
						},
						{
							path: 'export',
							component: ExportReport,
							children: [
								{
									path: ':uuid',
									component: ExportReportDialog,
								},
							],
						},
					],
				},
			],
		},
	],
});

const SETTINGS_ROLES = {
	'my-account': '333',
	users: '666',
	company: '666',
	'email-layouts': '666',
	'email-templates': '666',
	'instant-booking': '666',
	'feedback-templates': '666',
	'scorecard-templates': '666',
	'requisition-form-templates': '666',
	'requisition-approval-flow-templates': '666',
	'form-templates': '666',
	'approval-templates': '666',
	rulesets: '777',
	tags: '666',
	'job-site': '666',
	'resource-usage': '777',
};

router.beforeEach((to, from, next) => {
	const { userRole } = store.state.currentUser;
	const pathsMinRole = SETTINGS_ROLES[to.name];

	if (pathsMinRole) {
		if (!userRole || Number(userRole) < Number(pathsMinRole)) {
			if (to.name !== 'Home') {
				next({ name: 'Home' });
			} else {
				next(false); // Cancel navigation to avoid loop
			}
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
