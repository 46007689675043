<template>
	<span v-if="candidate.tags?.length >= 1">
		<v-tooltip right>
			<template v-slot:activator="{ on }">
				<v-chip small class="mr-1" v-on="on">
					<v-icon left>label</v-icon>
					{{ candidate.tags?.length }}
				</v-chip>
			</template>
			<div>
				<v-chip
					v-for="(tagId, index) in candidate.tags"
					:key="`tag_${index}`"
					small
					class="mr-1"
				>
					<span>{{ handleGetTagName(tagId) }}</span>
				</v-chip>
			</div>
		</v-tooltip>
	</span>
</template>
<script>
export default {
	name: 'TagsCell',
	props: {
		tags: {
			type: Array,
			required: true,
			default() {
				return [];
			},
		},
		candidate: {
			type: Object,
			required: true,
			default() {
				return {};
			},
		},
	},
	methods: {
		handleGetTagName(tagId) {
			const { tags } = this;
			const tag = tags.find(item => item.id === tagId);
			return tag ? tag.name : tagId;
		},
	},
};
</script>
