<template>
	<div
		:style="`
      background: ${isDark ? '#424242' : '#eeeeee'};
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow-y: auto;
    `"
	>
		<v-container>
			<v-row
				v-for="(reportTemplate, index) in reportTemplates"
				:key="`reportTemplates_${index}`"
			>
				<v-col>
					<v-card elevation="1">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col>
										<h2>{{ reportTemplate.label }}</h2>
										<small>{{ reportTemplate.explanation }}</small>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-btn
											color="secondary"
											:to="`/${currentLang}/reports/export/${reportTemplate.uuid}`"
										>
											<v-icon left>upload_file</v-icon>
											{{ $t('button.export') }}
										</v-btn>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row v-if="reportTemplates.length === 0">
				<v-col>
					<v-card elevation="1">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col>
										<v-alert color="red" dark icon="report_problem">
											{{ $t('err.no-feedback-templates-found') }}
										</v-alert>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<!-- <v-row>
        <v-col>
          <v-btn
            v-if="currentUser.uuidv4 === '506aa161-3722-4f9b-a6be-ea88715fa6a7'"
            @click="handleAddReportTemplate"
          >
            add
          </v-btn>
        </v-col>
      </v-row> -->
		</v-container>
		<router-view></router-view>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/api';

export default {
	name: 'ExportReport',
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
			lang: state => state.currentUser.lang,
			currentUser: state => state.currentUser.currentUser,
			currentLang: state => state.currentUser.lang,
		}),
	},
	data() {
		return {
			reportTemplates: [],
		};
	},
	mounted() {
		api.reportTemplates.get({ uuid: undefined }).then(response => {
			const { items } = response.data;
			this.reportTemplates = items;
		});
	},
	methods: {
		handleAddReportTemplate() {
			api.reportTemplates.post({ items: [{ label: 'foo' }] });
		},
	},
};
</script>
