var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"position":"absolute","top":"0","right":"0","bottom":"0","left":"0"},attrs:{"tile":"","elevation":"0"}},[_c('div',{style:(`padding: .5rem; background: ${_vm.isDark ? '#121212' : '#ffffff'};`)},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.handleRefresh}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('tooltip.refresh'))+" ")])],1),_c('v-divider'),_c('div',{style:(`position: absolute; top: 53px; right: 0; bottom: 0; left: 0; overflow: auto; background: ${
			_vm.isDark ? '#424242' : '#eeeeee'
		};`)},[_c('v-container',{staticStyle:{"padding":"0"},attrs:{"fluid":"","fill-height":""}},[_c('v-layout',[_c('v-flex',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"tile":"","elevation":"1"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('DatePicker',{attrs:{"no-title":"","reactive":"","dense":"","range":"","label":_vm.$t('label.date-range')},on:{"input":_vm.handleDatePickerInput},model:{value:(_vm.datePickerDate),callback:function ($$v) {_vm.datePickerDate=$$v},expression:"datePickerDate"}})],1)],1),_c('v-row',[_c('v-col',[_c('JobPicker',{attrs:{"jobs":_vm.jobs,"label":_vm.$t('label.jobs'),"multiple":"","hide-inactive-teams":"","hide-inactive-departments":"","hide-draft-jobs":"","dense":""},on:{"input":() => _vm.handleFilterChange()},model:{value:(_vm.filters.job_id),callback:function ($$v) {_vm.$set(_vm.filters, "job_id", $$v)},expression:"filters.job_id"}})],1)],1),_c('v-row',[_c('v-col',[_c('UserPicker',{attrs:{"users":_vm.users.filter(item => item.active),"label":_vm.$t('label.owner'),"multiple":"","dense":""},on:{"input":() => _vm.handleFilterChange()},model:{value:(_vm.filters.owner),callback:function ($$v) {_vm.$set(_vm.filters, "owner", $$v)},expression:"filters.owner"}})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"tile":"","elevation":"1"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tabs',{attrs:{"align-with-title":"","slider-size":"3","slider-color":"primary"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('tab.applicant'))+" ")])],1),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',[_c('ChartStackedBar',{attrs:{"chartdata":_vm.applicantChartdata,"onClick":({ index, datasetIndex }) =>
													_vm.handleChartClick({
														index,
														datasetIndex,
														chartDataKey: 'applicantChartdata',
														candidateStatus: 'applicant',
													})}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"tile":"","elevation":"1"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tabs',{attrs:{"align-with-title":"","slider-size":"3","slider-color":"primary"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('tab.lead'))+" ")])],1),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',[_c('ChartStackedBar',{attrs:{"chartdata":_vm.leadChartdata,"onClick":({ index, datasetIndex }) =>
													_vm.handleChartClick({
														index,
														datasetIndex,
														chartDataKey: 'leadChartdata',
														candidateStatus: 'lead',
													})}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"tile":"","elevation":"1"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tabs',{attrs:{"align-with-title":"","slider-size":"3","slider-color":"primary"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('tab.interview'))+" ")])],1),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',[_c('ChartStackedBar',{attrs:{"chartdata":_vm.interviewChartdata,"onClick":({ index, datasetIndex }) =>
													_vm.handleChartClick({
														index,
														datasetIndex,
														chartDataKey: 'interviewChartdata',
														candidateStatus: 'interview',
													})}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"tile":"","elevation":"1"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tabs',{attrs:{"align-with-title":"","slider-size":"3","slider-color":"primary"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('tab.archive'))+" ")])],1),_c('v-spacer')],1)],1),_c('v-divider'),_c('v-card-text',[_c('ChartStackedBar',{attrs:{"chartdata":_vm.archiveChartdata,"onClick":({ index, datasetIndex }) =>
													_vm.handleChartClick({
														index,
														datasetIndex,
														chartDataKey: 'archiveChartdata',
														candidateStatus: 'archive',
													})}})],1)],1)],1)],1)],1)],1)],1)],1)],1),(_vm.isReportCandidatesDialogVisible)?_c('ReportCandidatesDialog',{attrs:{"candidateStatus":_vm.reportCandidatesDialogCandidateStatus,"pipelineStatusKey":_vm.reportCandidatesDialogPipelineStatusKey,"pipelineStatusValue":_vm.reportCandidatesDialogPipelineStatusValue,"date":_vm.reportCandidatesDialogDate,"onClose":_vm.handleReportCandidatesDialogClose}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }