<template>
	<v-container fluid fill-height style="padding: 0">
		<v-navigation-drawer
			:value="drawer"
			:clipped="$vuetify.breakpoint.lgAndUp"
			app
			:color="`${isDark ? '#121212' : 'white'}`"
		>
			<v-container>
				<v-row v-if="userRole >= 444">
					<v-col>
						<v-btn rounded large @click="handleAddNewCandidate">
							<v-icon>add</v-icon>
							{{ $t('button.new-candidate-uppercase') }}
						</v-btn>
					</v-col>
				</v-row>
				<v-row v-if="true">
					<v-col>
						<fieldset class="talentics-fieldset">
							<legend>
								{{ $t('label.sort-candidates-by') }}
							</legend>
							<v-list class="v-list-paddingless" dense>
								<v-list-item-group
									v-model="filters.order_by"
									@change="handleOrderByChange"
								>
									<v-list-item value="created">
										<v-list-item-content>
											<v-list-item-title>{{
												$t('label.pinned-first')
											}}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
									<v-list-item value="application_date">
										<v-list-item-content>
											<v-list-item-title>{{
												$t('label.last-applied')
											}}</v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</fieldset>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<JobPicker
							v-model="filters.job_id"
							:jobs="jobs"
							:label="$t('label.jobs')"
							multiple
							@input="() => handleFilterChange({ goToFirstPage: true })"
							hide-inactive-teams
							hide-inactive-departments
							hide-draft-jobs
							:hide-closed-jobs="false"
							dense
						></JobPicker>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<UserPicker
							v-model="filters.owner"
							:users="users.filter(item => item.active)"
							:label="$t('label.owner')"
							multiple
							@input="() => handleFilterChange({ goToFirstPage: true })"
							dense
						></UserPicker>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-autocomplete
							v-model="filters.tags"
							:items="tags.filter(item => item.active)"
							item-text="name"
							item-value="id"
							outlined
							dense
							chips
							small-chips
							multiple
							:label="`${$t('label.tags')}`"
							hide-details
							deletable-chips
							@input="() => handleFilterChange({ goToFirstPage: true })"
						>
							<template v-slot:prepend-item>
								<div>
									<v-btn-toggle
										v-model="filters.tag_operator"
										@change="
											() =>
												handleFilterChange({
													goToFirstPage: true,
												})
										"
										style="margin-bottom: 8px; margin-left: 16px"
									>
										<v-btn small value="or">
											{{ $t('label.or') }}
										</v-btn>
										<v-btn small value="and">
											{{ $t('label.and') }}
										</v-btn>
									</v-btn-toggle>
								</div>
								<v-divider></v-divider>
							</template>
						</v-autocomplete>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<TextField
							v-model="filters.name"
							:label="`${$t('label.candidate-name')}`"
							:debounce="600"
							@input="() => handleFilterChange({ goToFirstPage: true })"
							clearable
						></TextField>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<ValidationObserver
							ref="form_candidate_filters"
							tag="form"
							@submit="
								evt =>
									handleFilterChange({
										goToFirstPage: true,
										evt,
									})
							"
							id="form_candidate_filters"
						>
							<TextField
								v-model="filters.email"
								:label="`${$t('label.candidate-email')}`"
								:debounce="0"
								:onBlur="
									() =>
										handleFilterChange({
											goToFirstPage: true,
										})
								"
								clearable
								rules="email"
							></TextField>
						</ValidationObserver>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<TextField
							v-model="filters.work_experience"
							:label="`${$t('label.work-experience')}`"
							:debounce="600"
							@input="() => handleFilterChange({ goToFirstPage: true })"
							clearable
						></TextField>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<TextField
							v-model="filters.education"
							:label="`${$t('label.education')}`"
							:debounce="600"
							@input="() => handleFilterChange({ goToFirstPage: true })"
							clearable
						></TextField>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<fieldset class="talentics-fieldset talentics-fieldset-paddings">
							<legend>
								{{ $t('label.years-of-experience') }}
							</legend>
							<v-row>
								<v-col cols="6">
									<v-select
										v-model="filters.min_total_years_experience"
										:items="totalYearsItems"
										:label="$t('label.min')"
										outlined
										hide-details
										dense
										@change="
											() =>
												handleFilterChange({
													goToFirstPage: true,
												})
										"
										clearable
									></v-select>
								</v-col>
								<v-col cols="6">
									<v-select
										v-model="filters.max_total_years_experience"
										:items="totalYearsItems"
										:label="$t('label.max')"
										outlined
										hide-details
										dense
										@change="
											() =>
												handleFilterChange({
													goToFirstPage: true,
												})
										"
										clearable
									></v-select>
								</v-col>
							</v-row>
						</fieldset>
					</v-col>
				</v-row>
			</v-container>
		</v-navigation-drawer>
		<v-layout>
			<v-flex style="position: relative">
				<v-card
					tile
					elevation="0"
					:color="`${isDark ? '#121212' : 'white'}`"
					style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
				>
					<div style="padding: 0.5rem; display: flex">
						<CheckboxWithMenu
							:checked="allChecked"
							:indeterminate="indeterminateChecked"
							:onSelectNone="handleSelectNone"
							:onSelectAll="handleSelectAll"
						>
							<v-list>
								<v-list-item @click="handleSelectAll">
									<v-list-item-title>{{
										$t('text.select-all')
									}}</v-list-item-title>
								</v-list-item>
								<v-list-item @click="handleSelectNone">
									<v-list-item-title>{{
										$t('text.select-none')
									}}</v-list-item-title>
								</v-list-item>
							</v-list>
						</CheckboxWithMenu>
						<ListViewPicker
							v-if="
								currentUser.uuidv4 === '506aa161-3722-4f9b-a6be-ea88715fa6a7'
							"
						></ListViewPicker>
						<v-tooltip bottom v-if="!allChecked && !indeterminateChecked">
							<template v-slot:activator="{ on }">
								<v-btn icon @click="handleRefresh" v-on="on">
									<v-icon>refresh</v-icon>
								</v-btn>
							</template>
							{{ $t('tooltip.refresh') }}
						</v-tooltip>
						<v-menu v-if="allChecked || indeterminateChecked" offset-y>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" style="margin-left: 0.5rem">
									<v-icon left>archive</v-icon>
									{{ $t('tooltip.archive') }}
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									v-for="(item, index) in pipelineArchiveItems.map(item => ({
										text: item,
										value: item,
									}))"
									:key="`pipelineArchiveItem_${index}`"
									@click="
										() =>
											handleBulkArchive({
												step: item.value,
											})
									"
								>
									<v-list-item-title>{{
										$t(`pipeline.steps.${item.text}`)
									}}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
						<v-menu
							v-if="allChecked || indeterminateChecked"
							offset-y
							:max-height="400"
						>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" style="margin-left: 0.5rem">
									<v-icon left>flag</v-icon>
									{{ $t('tooltip.change-stage') }}
								</v-btn>
							</template>
							<v-list>
								<v-subheader>
									{{ $t('subheader.lead') }}
								</v-subheader>
								<v-list-item
									v-for="(item, index) in pipelineLeadItems.map(item => ({
										text: item,
										value: item,
									}))"
									:key="`pipelineLeadItem_${index}`"
									@click="
										() =>
											handleBulkApplicationStatus({
												step: item.value,
											})
									"
								>
									<v-list-item-title>{{
										$t(`pipeline.steps.${item.text}`)
									}}</v-list-item-title>
								</v-list-item>
								<v-divider></v-divider>
								<v-subheader>
									{{ $t('subheader.application') }}
								</v-subheader>
								<v-list-item
									@click="
										() =>
											handleBulkApplicationStatus({
												step: 'new-applicant',
											})
									"
								>
									<v-list-item-title>{{
										$t('pipeline.steps.new-applicant')
									}}</v-list-item-title>
								</v-list-item>
								<v-divider></v-divider>
								<v-subheader>
									{{ $t('subheader.interview') }}
								</v-subheader>
								<v-list-item
									v-for="(item, index) in pipelineInterviewItems.map(item => ({
										text: item,
										value: item,
									}))"
									:key="`pipelineInterviewItem_${index}`"
									@click="
										() =>
											handleBulkApplicationStatus({
												step: item.value,
											})
									"
								>
									<v-list-item-title>{{
										$t(`pipeline.steps.${item.text}`)
									}}</v-list-item-title>
								</v-list-item>
								<v-list-item
									@click="
										() =>
											handleBulkArchive({
												step: 'archive-hired',
											})
									"
								>
									<v-list-item-title>{{
										$t('pipeline.steps.archive-hired')
									}}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
						<v-btn
							style="margin-left: 0.5rem"
							v-if="allChecked || indeterminateChecked"
							@click="handleClickSendBulkEmail"
						>
							<v-icon left>email</v-icon>
							{{ $t('button.send-email') }}
						</v-btn>
						<v-spacer></v-spacer>
						<div
							v-if="candidates.length > 0"
							style="
								font-size: 0.75rem;
								color: #5f6368;
								white-space: nowrap;
								line-height: 36px;
							"
						>
							{{
								$t('text.candidates-length', {
									total,
									startIndex,
									endIndex,
								})
							}}
						</div>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<div v-on="on">
									<v-btn
										icon
										@click="
											() =>
												handlePageChange({
													page: (filters.page || 1) - 1,
												})
										"
										:disabled="(filters.page || 1) === 1"
									>
										<v-icon>keyboard_arrow_left</v-icon>
									</v-btn>
								</div>
							</template>
							{{ $t('tooltip.newer') }}
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<div v-on="on">
									<v-btn
										icon
										@click="
											() =>
												handlePageChange({
													page: (filters.page || 1) + 1,
												})
										"
										:disabled="total <= (filters.page || 1) * 50"
										style="margin-right: 1rem"
									>
										<v-icon>keyboard_arrow_right</v-icon>
									</v-btn>
								</div>
							</template>
							{{ $t('tooltip.older') }}
						</v-tooltip>
						<PanePicker></PanePicker>
					</div>
					<v-divider></v-divider>
					<div
						style="
							position: absolute;
							top: 53px;
							right: 0;
							bottom: 0;
							left: 0;
							overflow: auto;
						"
					>
						<div v-if="tabsVisible">
							<v-tabs
								v-model="tab"
								@change="handleTabChange"
								slider-size="3"
								slider-color="primary"
								:height="56"
							>
								<v-tab>
									<v-badge
										:color="
											tab === 0
												? 'primary'
												: isDark
												? 'grey darken-1'
												: 'grey lighten-1'
										"
										:content="counters.leads.toString()"
										inline
										:value="!countersLoading"
									>
										<v-icon left>verified</v-icon>
										{{ $t('tab.lead') }}
										<v-progress-circular
											v-if="countersLoading"
											indeterminate
											:color="
												tab === 0
													? 'primary'
													: isDark
													? 'grey darken-1'
													: 'grey lighten-1'
											"
											:size="20"
											style="margin-left: 0.25rem"
										></v-progress-circular>
									</v-badge>
								</v-tab>
								<v-tab>
									<v-badge
										:color="
											tab === 1
												? 'primary'
												: isDark
												? 'grey darken-1'
												: 'grey lighten-1'
										"
										:content="counters.applieds.toString()"
										inline
										:value="!countersLoading"
									>
										<v-icon left>person_pin</v-icon>
										{{ $t('tab.applicant') }}
										<v-progress-circular
											v-if="countersLoading"
											indeterminate
											:color="
												tab === 1
													? 'primary'
													: isDark
													? 'grey darken-1'
													: 'grey lighten-1'
											"
											:size="20"
											style="margin-left: 0.25rem"
										></v-progress-circular>
									</v-badge>
								</v-tab>
								<v-tab>
									<v-badge
										:color="
											tab === 2
												? 'primary'
												: isDark
												? 'grey darken-1'
												: 'grey lighten-1'
										"
										:content="counters.interviews.toString()"
										inline
										:value="!countersLoading"
									>
										<v-icon left>person_search</v-icon>
										{{ $t('tab.interview') }}
										<v-progress-circular
											v-if="countersLoading"
											indeterminate
											:color="
												tab === 2
													? 'primary'
													: isDark
													? 'grey darken-1'
													: 'grey lighten-1'
											"
											:size="20"
											style="margin-left: 0.25rem"
										></v-progress-circular>
									</v-badge>
								</v-tab>
								<v-tab>
									<v-badge
										:color="
											tab === 3
												? 'primary'
												: isDark
												? 'grey darken-1'
												: 'grey lighten-1'
										"
										:content="counters.archived.toString()"
										inline
										:value="!countersLoading"
									>
										<v-icon left>archive</v-icon>
										{{ $t('tab.archive') }}
										<v-progress-circular
											v-if="countersLoading"
											indeterminate
											:color="
												tab === 3
													? 'primary'
													: isDark
													? 'grey darken-1'
													: 'grey lighten-1'
											"
											:size="20"
											style="margin-left: 0.25rem"
										></v-progress-circular>
									</v-badge>
								</v-tab>
							</v-tabs>
						</div>
						<div v-if="tabsVisible && filters.is_interview">
							<v-divider></v-divider>
							<v-tabs
								v-model="interviewPipelineTab"
								fixed-tabs
								@change="
									newVal =>
										handleInterviewStatusChange({
											newVal,
											key: 'interview_status',
										})
								"
								class="v-tabs-status"
								slider-size="3"
								slider-color="primary"
							>
								<v-tab
									v-for="(pipelineItem, index) in pipelineInterviewItems"
									:key="index"
								>
									<v-badge
										:color="
											interviewPipelineTab === index
												? 'primary'
												: isDark
												? 'grey darken-1'
												: 'grey lighten-1'
										"
										:content="
											(interviewCounters[pipelineItem]
												? interviewCounters[pipelineItem]
												: 0
											).toString()
										"
										inline
										:value="!interviewCountersLoading"
									>
										{{ $t(`pipeline.tab-steps.${pipelineItem}`) }}
										<v-progress-circular
											v-if="interviewCountersLoading"
											indeterminate
											:color="
												interviewPipelineTab === index
													? 'primary'
													: isDark
													? 'grey darken-1'
													: 'grey lighten-1'
											"
											:size="20"
											style="margin-left: 0.25rem"
										></v-progress-circular>
									</v-badge>
								</v-tab>
							</v-tabs>
						</div>
						<div v-if="tabsVisible && filters.is_archived">
							<v-divider></v-divider>
							<v-tabs
								v-model="archivePipelineTab"
								fixed-tabs
								@change="
									newVal =>
										handleInterviewStatusChange({
											newVal,
											key: 'archive_status',
										})
								"
								class="v-tabs-status"
								slider-size="3"
								slider-color="primary"
							>
								<v-tab
									v-for="(pipelineItem, index) in pipelineArchiveItems"
									:key="index"
								>
									<v-badge
										:color="
											archivePipelineTab === index
												? 'primary'
												: isDark
												? 'grey darken-1'
												: 'grey lighten-1'
										"
										:content="
											(archiveCounters[pipelineItem]
												? archiveCounters[pipelineItem]
												: 0
											).toString()
										"
										inline
										:value="!archiveCountersLoading"
									>
										{{ $t(`pipeline.tab-steps.${pipelineItem}`) }}
										<v-progress-circular
											v-if="archiveCountersLoading"
											indeterminate
											:color="
												archivePipelineTab === index
													? 'primary'
													: isDark
													? 'grey darken-1'
													: 'grey lighten-1'
											"
											:size="20"
											style="margin-left: 0.25rem"
										></v-progress-circular>
									</v-badge>
								</v-tab>
							</v-tabs>
						</div>
						<div v-if="tabsVisible && filters.is_lead">
							<v-divider></v-divider>
							<v-tabs
								v-model="leadPipelineTab"
								@change="
									newVal =>
										handleInterviewStatusChange({
											newVal,
											key: 'lead_status',
										})
								"
								class="v-tabs-status"
								slider-size="3"
								slider-color="primary"
							>
								<v-tab
									v-for="(pipelineItem, index) in pipelineLeadItems"
									:key="index"
								>
									<v-badge
										:color="
											leadPipelineTab === index
												? 'primary'
												: isDark
												? 'grey darken-1'
												: 'grey lighten-1'
										"
										:content="
											(leadCounters[pipelineItem]
												? leadCounters[pipelineItem]
												: 0
											).toString()
										"
										inline
										:value="!leadCountersLoading"
									>
										{{ $t(`pipeline.tab-steps.${pipelineItem}`) }}
										<v-progress-circular
											v-if="leadCountersLoading"
											indeterminate
											:color="
												leadPipelineTab === index
													? 'primary'
													: isDark
													? 'grey darken-1'
													: 'grey lighten-1'
											"
											:size="20"
											style="margin-left: 0.25rem"
										></v-progress-circular>
									</v-badge>
								</v-tab>
							</v-tabs>
						</div>
						<div>
							<v-divider></v-divider>
							<v-simple-table
								class="candidates-table"
								v-if="candidates.length > 0"
							>
								<tbody
									v-for="(candidate, index) in candidates"
									:key="index"
									:class="`${candidate.checked ? 'checked' : ''} ${
										candidate.id == activeCandidateId ? 'checked' : ''
									} ${listViewMode}`"
								>
									<tr class="first-row" style="cursor: pointer">
										<td style="width: 0.1%; padding-right: 0">
											<v-checkbox
												v-model="candidate.checked"
												dense
												hide-details
												class="candidate-checkbox"
												:color="isDark ? 'white' : '#121212'"
											></v-checkbox>
										</td>
										<td style="width: 0.1%; padding: 0">
											<v-tooltip bottom>
												<template v-slot:activator="{ on }">
													<v-btn
														icon
														@click="
															() =>
																handlePinCandidateClick(
																	candidate.id,
																	candidate.is_pinned,
																)
														"
														v-on="on"
													>
														<v-icon
															size="20"
															:color="candidate.is_pinned ? '#eab73e' : ''"
															>{{
																candidate.is_pinned ? 'star' : 'star_border'
															}}</v-icon
														>
													</v-btn>
												</template>
												<span>{{
													candidate.is_pinned
														? $t('tooltip.starred')
														: $t('tooltip.not-starred')
												}}</span>
											</v-tooltip>
										</td>
										<td
											@click="
												() =>
													handleCandidateClick({
														id: candidate.id,
													})
											"
										>
											<span style="white-space: nowrap">{{
												candidate.name
											}}</span>
											<span style="margin-left: 0.5rem">
												<TagsCell :tags="tags" :candidate="candidate" />
											</span>
										</td>
										<td
											@click="
												() =>
													handleCandidateClick({
														id: candidate.id,
													})
											"
										>
											<ApplicationsCell
												:jobIds="candidate.job_id"
												:applications="candidate.applications"
											></ApplicationsCell>
										</td>
										<td
											@click="
												() =>
													handleCandidateClick({
														id: candidate.id,
													})
											"
										>
											{{ candidate.current_company }}
										</td>
										<td
											style="width: 0.1%"
											@click="
												() =>
													handleCandidateClick({
														id: candidate.id,
													})
											"
										>
											<CandidateFeedbackIcons
												:feedbacks="candidate.feedbacks"
												:scorecards="candidate.scorecards"
											></CandidateFeedbackIcons>
										</td>
										<td
											@click="
												() =>
													handleCandidateClick({
														id: candidate.id,
													})
											"
											style="width: 0.1%"
										>
											{{
												formatDate({
													timestamp: candidate.created,
												})
											}}
										</td>
										<td
											style="width: 0.1%"
											v-if="
												filters.is_interview &&
												currentUser &&
												currentUser.can_schedule_calendar_events
											"
										>
											<span v-if="candidate.events.length === 0">
												<v-btn
													small
													@click="
														handleScheduleClick({
															id: candidate.id,
														})
													"
												>
													<v-icon left>date_range</v-icon>
													{{ $t('button.schedule') }}
												</v-btn>
											</span>
											<span v-if="candidate.events.length > 0">
												<v-btn
													small
													@click="
														handleScheduleClick({
															id: candidate.id,
														})
													"
												>
													<v-icon left>event</v-icon>
													{{
														formatDate({
															timestamp: candidate.events[0].start_datetime,
															format: 'dd.MM - HH:mm',
														})
													}}
												</v-btn>
											</span>
										</td>
										<td
											@click="
												() =>
													handleCandidateClick({
														id: candidate.id,
													})
											"
											style="width: 0.1%; text-align: right"
										>
											<span style="white-space: nowrap">
												<span
													v-for="(owner, ownerIndex) in candidate.owners"
													:key="`owner_${ownerIndex}`"
													:style="`margin-left: ${
														ownerIndex === 0 ? '0' : '-16px'
													};`"
												>
													<Avatar
														:userId="owner"
														:tooltipAppend="`${$t('label.posting-owner')}: `"
													></Avatar>
												</span>
											</span>
										</td>
									</tr>
									<tr v-if="listViewMode === 'detailed'" class="second-row">
										<td colspan="7">
											<div
												style="
													margin-left: 48px;
													margin-top: 16px;
													margin-bottom: 16px;
													max-width: 400px;
												"
											>
												<v-row
													v-if="
														candidate.current_company &&
														candidate.current_company.length > 0
													"
												>
													<v-col>
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>
																{{ $t('label.current-company') }}
															</legend>
															<div>
																{{ candidate.current_company }}
															</div>
														</fieldset>
													</v-col>
												</v-row>

												<v-row
													v-if="
														candidate.last_school &&
														candidate.last_school.length > 0
													"
												>
													<v-col>
														<fieldset
															class="talentics-fieldset talentics-fieldset-paddings"
														>
															<legend>
																{{ $t('label.last-school-attended') }}
															</legend>
															<div>
																{{ candidate.last_school }}
															</div>
														</fieldset>
													</v-col>
												</v-row>
											</div>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
							<div v-if="candidates.length === 0 && loading === true">
								<v-simple-table class="candidates-table">
									<tbody>
										<tr
											v-for="(item, key) in (0,
											1,
											2,
											3,
											4,
											5,
											6,
											7,
											8,
											9,
											10,
											11,
											12,
											13,
											14,
											15,
											16,
											17,
											18,
											19)"
											:key="`placeholder_${key}`"
										>
											<td style="width: 0.1%; padding-right: 0">
												<v-checkbox
													dense
													hide-details
													class="candidate-checkbox"
													:color="isDark ? 'white' : '#121212'"
												></v-checkbox>
											</td>
											<td>
												<v-progress-linear
													color="grey lighten-2"
													height="6"
													indeterminate
													reverse
												></v-progress-linear>
											</td>
											<td>
												<v-progress-linear
													color="grey lighten-2"
													height="6"
													indeterminate
													reverse
												></v-progress-linear>
											</td>
											<td>
												<v-progress-linear
													color="grey lighten-2"
													height="6"
													indeterminate
													reverse
												></v-progress-linear>
											</td>
											<td style="width: 5%">
												<v-progress-linear
													color="grey lighten-2"
													height="6"
													indeterminate
													reverse
												></v-progress-linear>
											</td>
											<td style="width: 3%">
												<v-progress-linear
													color="grey lighten-2"
													height="6"
													indeterminate
													reverse
												></v-progress-linear>
											</td>
										</tr>
									</tbody>
								</v-simple-table>
							</div>
							<div
								v-else-if="candidates.length === 0 && loading === false"
								style="padding: 1rem"
							>
								{{ $t('err.no-candidates-found') }}
							</div>
						</div>
					</div>
					<router-view></router-view>
				</v-card>
				<ArchiveCandidateDialog
					v-if="isArchiveCandidateDialogVisible"
					bulk-action
					:archive-reason-initial="archiveReasonToArchive"
					:candidates="candidates.filter(candidate => candidate.checked)"
					:onClose="handleArchiveCandidateDialogClose"
				></ArchiveCandidateDialog>
				<EditApplicationStatusDialog
					v-if="isApplicationStatusDialogVisible"
					:status-initial="bulkApplicationStatus"
					:candidates="candidates.filter(candidate => candidate.checked)"
					:onClose="handleApplicationStatusDialogClose"
				></EditApplicationStatusDialog>
				<ScheduleCandidateDialog
					v-if="isScheduleCandidateDialogVisible"
					:candidateId="candidateIdToSchedule"
					:onClose="handleScheduleCandidateDialogClose"
					:onSuccess="handleScheduleCandidateDialogSuccess"
				></ScheduleCandidateDialog>
				<ScheduleBulkEmail
					v-if="isScheduleBulkEmailVisible"
					:onClose="handleScheduleBulkEmailClose"
					:candidates="candidates.filter(candidate => candidate.checked)"
				></ScheduleBulkEmail>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import { ValidationObserver } from 'vee-validate';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import JobPicker from '@/components/shared/jobPicker/JobPicker';
import Avatar from '@/components/shared/avatar/Avatar';
import CheckboxWithMenu from '@/components/shared/checkboxWithMenu/CheckboxWithMenu';
import ArchiveCandidateDialog from '@/components/routes/candidates/archive/archiveCandidateDialog/ArchiveCandidateDialog';
import EditApplicationStatusDialog from '@/components/routes/candidates/editApplicationStatusDialog/EditApplicationStatusDialog';
import ScheduleCandidateDialog from '@/components/routes/candidates/scheduleCandidateDialog/ScheduleCandidateDialog';
import CandidateFeedbackIcons from './candidateFeedbackIcons/CandidateFeedbackIcons';
import ApplicationsCell from './applicationsCell/ApplicationsCell';
import TagsCell from './tagsCell/TagsCell';
import TextField from '@/components/shared/textField/TextField';
import ScheduleBulkEmail from './scheduleBulkEmail/ScheduleBulkEmail';
import PanePicker from '@/components/shared/panePicker/PanePicker';
import ListViewPicker from '@/components/shared/listViewPicker/ListViewPicker';

export default {
	name: 'Candidates',
	components: {
		ValidationObserver,
		UserPicker,
		JobPicker,
		Avatar,
		CheckboxWithMenu,
		ArchiveCandidateDialog,
		EditApplicationStatusDialog,
		ScheduleCandidateDialog,
		CandidateFeedbackIcons,
		ApplicationsCell,
		TextField,
		ScheduleBulkEmail,
		PanePicker,
		ListViewPicker,
		TagsCell,
	},
	created() {
		this.$store.commit('currentRoute/setRouteFirstLevel', {
			routeName: 'candidates',
		});
		this.$store.dispatch('organizations/get', { updateStore: true });
		this.$store.dispatch('departments/get', { updateStore: true });
		this.$store.dispatch('teams/get', { updateStore: true });
		this.$store.dispatch('jobs/get', { updateStore: true });
		this.$store.dispatch('users/get', { updateStore: true });
		this.$store.dispatch('tags/get', { updateStore: true });
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			userRole: state => state.currentUser.userRole,
			currentLang: state => state.currentUser.lang,
			jobs: state => state.jobs.items,
			users: state => state.users.items,
			drawer: state => state.currentRoute.drawer,
			itemsNeedToRefresh: state => state.candidates.itemsNeedToRefresh,
			counters: state => state.applicants.counters,
			interviewCounters: state => state.applicants.interviewCounters,
			archiveCounters: state => state.applicants.archiveCounters,
			leadCounters: state => state.applicants.leadCounters,
			countersLoading: state => state.applicants.countersLoading,
			leadCountersLoading: state => state.applicants.leadCountersLoading,
			interviewCountersLoading: state =>
				state.applicants.interviewCountersLoading,
			archiveCountersLoading: state => state.applicants.archiveCountersLoading,
			loading: state => state.candidates.loading,
			pipelineInterviewItems: state => state.pipelineInterview.items,
			pipelineArchiveItems: state => state.pipelineArchive.items,
			pipelineLeadItems: state => state.pipelineLead.items,
			isDark: state => state.currentUser.isDark,
			tags: state => state.tags.items,
			listViewMode: state => state.currentUser.listViewMode,
		}),
		allChecked() {
			const { candidates } = this;
			const checkedCandidates = candidates.filter(
				candidate => candidate.checked,
			);
			return (
				candidates.length > 0 && candidates.length === checkedCandidates.length
			);
		},
		indeterminateChecked() {
			const { candidates } = this;
			const checkedCandidates = candidates.filter(
				candidate => candidate.checked,
			);
			return (
				candidates.length > 0 &&
				checkedCandidates.length > 0 &&
				candidates.length > checkedCandidates.length
			);
		},
	},
	data() {
		const {
			owner,
			job_id,
			is_lead,
			is_interview,
			is_archived,
			interview_status,
			archive_status,
			lead_status,
			page,
			tags,
			work_experience,
			education,
			min_total_years_experience,
			max_total_years_experience,
			name,
			email,
			order_by,
			tag_operator,
		} = this.$route.query;
		let tab = 1;
		if (is_lead) {
			tab = 0;
		} else if (is_interview) {
			tab = 2;
		} else if (is_archived) {
			tab = 3;
		}

		const candidatesOrderBy = localStorage.getItem('candidatesOrderBy');

		return {
			format,
			candidates: [],
			filters: {
				job_id: job_id ? job_id.split(',').map(item => parseInt(item, 10)) : [],
				owner: owner ? owner.split(',').map(item => parseInt(item, 10)) : [],
				is_lead: is_lead ? Boolean(is_lead) : undefined,
				is_interview: is_interview ? Boolean(is_interview) : undefined,
				is_archived: is_archived ? Boolean(is_archived) : undefined,
				interview_status: interview_status ? interview_status : undefined,
				archive_status: archive_status ? archive_status : undefined,
				lead_status: lead_status ? lead_status : undefined,
				page: page ? parseInt(page) : 1,
				tags: tags ? tags.split(',').map(item => parseInt(item, 10)) : [],
				work_experience: work_experience ? work_experience : undefined,
				education: education ? education : undefined,
				min_total_years_experience: min_total_years_experience
					? parseInt(min_total_years_experience, 10)
					: undefined,
				max_total_years_experience: max_total_years_experience
					? parseInt(max_total_years_experience, 10)
					: undefined,
				name: name ? name : undefined,
				email: email ? email : undefined,
				order_by: candidatesOrderBy || (order_by ? order_by : 'created'),
				tag_operator: tag_operator ? tag_operator : 'or',
			},
			tab,
			interviewPipelineTab: 0,
			archivePipelineTab: 0,
			leadPipelineTab: 0,
			isArchiveCandidateDialogVisible: false,
			isApplicationStatusDialogVisible: false,
			isScheduleCandidateDialogVisible: false,
			tabsVisible: false,
			archiveReasonToArchive: null,
			bulkApplicationStatus: null,
			startIndex: null,
			endIndex: null,
			total: null,
			candidateIdToSchedule: null,
			totalYearsItems: [
				{ text: '1', value: 1 },
				{ text: '2', value: 2 },
				{ text: '3', value: 3 },
				{ text: '4', value: 4 },
				{ text: '5', value: 5 },
				{ text: '6', value: 6 },
				{ text: '7', value: 7 },
				{ text: '8', value: 8 },
				{ text: '9', value: 9 },
				{ text: '10+', value: 10 },
			],
			isScheduleBulkEmailVisible: false,
			activeCandidateId: null,
		};
	},
	methods: {
		setPipelineTabs() {
			const {
				is_interview,
				interview_status,
				is_lead,
				lead_status,
				is_archived,
				archive_status,
			} = this.filters;
			const {
				pipelineInterviewItems,
				pipelineLeadItems,
				pipelineArchiveItems,
			} = this;
			if (is_interview && pipelineInterviewItems.length > 0) {
				const tabIndex = pipelineInterviewItems.indexOf(interview_status);
				if (tabIndex > -1) {
					this.interviewPipelineTab = tabIndex;
				}
			} else if (is_lead && pipelineLeadItems.length > 0) {
				const tabIndex = pipelineLeadItems.indexOf(lead_status);
				if (tabIndex > -1) {
					this.leadPipelineTab = tabIndex;
				}
			} else if (is_archived && pipelineArchiveItems.length > 0) {
				const tabIndex = pipelineArchiveItems.indexOf(archive_status);
				if (tabIndex > -1) {
					this.archivePipelineTab = tabIndex;
				}
			}
		},
		getCandidates({ query }, updateStore = false) {
			const candidatesOrderBy = localStorage.getItem('candidatesOrderBy');
			query.order_by = candidatesOrderBy;

			this.$store
				.dispatch('candidates/get', {
					...query,
					updateStore,
					refresh: true,
				})
				.then(response => {
					const { items, start_index, end_index, total } = response.data;
					this.candidates = items;
					this.startIndex = start_index;
					this.endIndex = end_index;
					this.total = total;
				});
		},
		getCandidateCounters({
			query,
			updateCounters = true,
			updateInterviewCounters = true,
			updateArchiveCounters = true,
			updateLeadCounters = true,
		}) {
			if (updateCounters) {
				this.$store.dispatch('applicants/get_counters', {
					...query,
					refresh: true,
					updateStore: true,
				});
			}
			const { pipelineInterviewItems } = this;
			const { is_interview } = this.filters;
			if (
				updateInterviewCounters &&
				pipelineInterviewItems.length > 0 &&
				is_interview
			) {
				this.$store.dispatch('applicants/get_counters_interview', {
					...query,
					statusItems: pipelineInterviewItems.join(','),
					refresh: true,
					updateStore: true,
				});
			}
			const { pipelineArchiveItems } = this;
			const { is_archived } = this.filters;
			if (
				updateArchiveCounters &&
				pipelineArchiveItems.length > 0 &&
				is_archived
			) {
				this.$store.dispatch('applicants/get_counters_archive', {
					...query,
					statusItems: pipelineArchiveItems.join(','),
					refresh: true,
					updateStore: true,
				});
			}
			const { pipelineLeadItems } = this;
			const { is_lead } = this.filters;
			if (updateLeadCounters && pipelineLeadItems.length > 0 && is_lead) {
				this.$store.dispatch('applicants/get_counters_lead', {
					...query,
					statusItems: pipelineLeadItems.join(','),
					refresh: true,
					updateStore: true,
				});
			}
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		async handleFilterChange({ goToFirstPage, evt }) {
			if (evt) {
				evt.preventDefault();
			}

			const isValid = await this.$refs.form_candidate_filters.validate();

			if (goToFirstPage) {
				this.filters = {
					...this.filters,
					page: 1,
				};
			}
			const {
				job_id,
				owner,
				is_lead,
				is_interview,
				is_archived,
				interview_status,
				archive_status,
				lead_status,
				page,
				tags,
				work_experience,
				education,
				min_total_years_experience,
				max_total_years_experience,
				name,
				email,
				order_by,
				tag_operator,
			} = this.filters;
			const query = {
				job_id: job_id.length > 0 ? job_id.join(',') : undefined,
				owner: owner.length > 0 ? owner.join(',') : undefined,
				tags: tags.length > 0 ? tags.join(',') : undefined,
				is_lead,
				is_interview,
				is_archived,
				interview_status,
				archive_status,
				lead_status,
				page,
				work_experience: work_experience ? work_experience : undefined,
				education: education ? education : undefined,
				min_total_years_experience: min_total_years_experience
					? min_total_years_experience
					: undefined,
				max_total_years_experience: max_total_years_experience
					? max_total_years_experience
					: undefined,
				name: name ? name : undefined,
				email: email && isValid ? email : undefined,
				order_by: order_by && order_by !== 'created' ? order_by : undefined,
				tag_operator: tag_operator ? tag_operator : undefined,
			};
			const { currentLang } = this;
			this.$router.push({
				path: `/${currentLang}/candidates`,
				query,
			});
		},
		handleCandidateClick({ id }) {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/candidates/edit/${id}`,
				query,
			});
		},
		handleRefresh() {
			const { query } = this.$route;
			this.getCandidates({ query });
			this.getCandidateCounters({ query });
		},
		handleTabChange(newVal) {
			this.interviewPipelineTab = 0;
			this.archivePipelineTab = 0;
			this.leadPipelineTab = 0;

			if (newVal === 0) {
				this.filters = {
					...this.filters,
					page: undefined,
					is_lead: 1,
					is_interview: undefined,
					is_archived: undefined,
					interview_status: undefined,
					archive_status: undefined,
					lead_status: undefined,
				};
			} else if (newVal === 1) {
				this.filters = {
					...this.filters,
					page: undefined,
					is_lead: undefined,
					is_interview: undefined,
					is_archived: undefined,
					interview_status: undefined,
					archive_status: undefined,
					lead_status: undefined,
				};
			} else if (newVal === 2) {
				this.filters = {
					...this.filters,
					page: undefined,
					is_lead: undefined,
					is_interview: 1,
					is_archived: undefined,
					interview_status: undefined,
					archive_status: undefined,
					lead_status: undefined,
				};
			} else if (newVal === 3) {
				this.filters = {
					...this.filters,
					page: undefined,
					is_lead: undefined,
					is_interview: undefined,
					is_archived: 1,
					interview_status: undefined,
					archive_status: undefined,
					lead_status: undefined,
				};
			}
			this.handleFilterChange({ goToFirstPage: false });
		},
		handleAddNewCandidate() {
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/candidates/new`,
				query,
			});
		},
		handleInterviewStatusChange({ newVal, key }) {
			const items = {
				interview_status: 'pipelineInterviewItems',
				archive_status: 'pipelineArchiveItems',
				lead_status: 'pipelineLeadItems',
			};
			this.filters = {
				...this.filters,
				page: undefined,
				[key]: this[items[key]][newVal],
			};
			this.handleFilterChange({ goToFirstPage: false });
		},
		handleSelectNone() {
			this.candidates = this.candidates.map(candidate => ({
				...candidate,
				checked: false,
			}));
		},
		handleSelectAll() {
			this.candidates = this.candidates.map(candidate => ({
				...candidate,
				checked: true,
			}));
		},
		handleArchiveCandidateDialogClose() {
			this.isArchiveCandidateDialogVisible = false;
		},
		handleBulkArchive({ step }) {
			this.archiveReasonToArchive = step;
			this.isArchiveCandidateDialogVisible = true;
		},
		handleApplicationStatusDialogClose() {
			this.isApplicationStatusDialogVisible = false;
		},
		handleBulkApplicationStatus({ step }) {
			this.bulkApplicationStatus = step;
			this.isApplicationStatusDialogVisible = true;
		},
		handlePageChange({ page }) {
			this.filters = {
				...this.filters,
				page,
			};
			this.handleFilterChange({ goToFirstPage: false });
		},
		handleScheduleCandidateDialogClose() {
			this.isScheduleCandidateDialogVisible = false;
		},
		handleScheduleCandidateDialogSuccess() {
			this.isScheduleCandidateDialogVisible = false;
			this.handleRefresh();
		},
		handleScheduleClick({ id }) {
			this.candidateIdToSchedule = id;
			this.isScheduleCandidateDialogVisible = true;
		},
		handleClickSendBulkEmail() {
			this.isScheduleBulkEmailVisible = true;
		},
		handleScheduleBulkEmailClose() {
			this.isScheduleBulkEmailVisible = false;
		},
		handleGetTagName(tagId) {
			const { tags } = this;
			console.log({ tags, tagId });
			const tag = tags.find(item => item.id === tagId);
			return tag ? tag.name : tagId;
		},
		handleOrderByChange(newVal) {
			localStorage.setItem('candidatesOrderBy', newVal);

			if (!newVal) {
				setTimeout(
					function () {
						this.filters = {
							...this.filters,
							order_by: 'created',
						};
						this.handleFilterChange({ goToFirstPage: true });
					}.bind(this),
					50,
				);
			} else {
				this.handleFilterChange({ goToFirstPage: true });
			}
		},
		handlePinCandidateClick(candidateId, isPinned) {
			const { query } = this.$route;

			if (isPinned) {
				this.$store
					.dispatch('candidates/unpinCandidate', { candidateId })
					.then(() => {
						this.getCandidates({ query }, true);
					});
			} else {
				this.$store
					.dispatch('candidates/pinCandidate', { candidateId })
					.then(() => {
						this.getCandidates({ query }, true);
					});
			}
		},
	},
	mounted() {
		this.setPipelineTabs();
		setTimeout(
			function () {
				this.tabsVisible = true;
			}.bind(this),
			500,
		);

		const candidatesOrderBy = localStorage.getItem('candidatesOrderBy');
		if (candidatesOrderBy) {
			this.filters = {
				...this.filters,
				order_by: candidatesOrderBy,
			};
		}
	},
	watch: {
		'$route.query': {
			handler: function (newVal, oldVal) {
				if (
					oldVal === undefined ||
					newVal.job_id !== oldVal.job_id ||
					newVal.owner !== oldVal.owner ||
					newVal.tags !== oldVal.tags ||
					newVal.is_lead !== oldVal.is_lead ||
					newVal.is_interview !== oldVal.is_interview ||
					newVal.is_archived !== oldVal.is_archived ||
					newVal.interview_status !== oldVal.interview_status ||
					newVal.archive_status !== oldVal.archive_status ||
					newVal.lead_status !== oldVal.lead_status ||
					newVal.page !== oldVal.page ||
					newVal.work_experience !== oldVal.work_experience ||
					newVal.education !== oldVal.education ||
					newVal.min_total_years_experience !==
						oldVal.min_total_years_experience ||
					newVal.max_total_years_experience !==
						oldVal.max_total_years_experience ||
					newVal.name !== oldVal.name ||
					newVal.email !== oldVal.email ||
					newVal.order_by !== oldVal.order_by ||
					newVal.tag_operator !== oldVal.tag_operator
				) {
					this.getCandidates({ query: newVal });
					const updateCounters =
						oldVal === undefined ||
						newVal.job_id !== oldVal.job_id ||
						newVal.owner !== oldVal.owner ||
						newVal.tags !== oldVal.tags ||
						newVal.work_experience !== oldVal.work_experience ||
						newVal.education !== oldVal.education ||
						newVal.min_total_years_experience !==
							oldVal.min_total_years_experience ||
						newVal.max_total_years_experience !==
							oldVal.max_total_years_experience ||
						newVal.name !== oldVal.name ||
						newVal.email !== oldVal.email ||
						newVal.order_by !== oldVal.order_by ||
						newVal.tag_operator !== oldVal.tag_operator;
					this.getCandidateCounters({
						query: newVal,
						updateCounters,
						updateInterviewCounters:
							updateCounters ||
							oldVal === undefined ||
							newVal.is_interview !== oldVal.is_interview,
						updateArchiveCounters:
							updateCounters ||
							oldVal === undefined ||
							newVal.is_archived !== oldVal.is_archived,
						updateLeadCounters:
							updateCounters ||
							oldVal === undefined ||
							newVal.is_lead !== oldVal.is_lead,
					});
				}
			},
			deep: true,
			immediate: true,
		},
		itemsNeedToRefresh() {
			this.handleRefresh();
		},
		pipelineInterviewItems() {
			const { query } = this.$route;
			this.getCandidateCounters({
				query,
				updateCounters: false,
				updateInterviewCounters: true,
				updateArchiveCounters: false,
				updateLeadCounters: false,
			});
			this.setPipelineTabs();
		},
		pipelineArchiveItems() {
			const { query } = this.$route;
			this.getCandidateCounters({
				query,
				updateCounters: false,
				updateInterviewCounters: false,
				updateArchiveCounters: true,
				updateLeadCounters: false,
			});
			this.setPipelineTabs();
		},
		pipelineLeadItems() {
			const { query } = this.$route;
			this.getCandidateCounters({
				query,
				updateCounters: false,
				updateInterviewCounters: false,
				updateArchiveCounters: false,
				updateLeadCounters: true,
			});
			this.setPipelineTabs();
		},
		'$route.params.candidateId': {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.activeCandidateId = newVal;
				}
			},
			deep: true,
			immediate: true,
		},
		listViewMode: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.handleRefresh();
				}
			},
			deep: true,
			immediate: false,
		},
	},
};
</script>

<style lang="scss">
.v-tabs-status {
	.v-tab {
		max-width: 1000px !important;
		font-size: 0.7rem;
	}
}

.v-input--selection-controls {
	&.candidate-checkbox {
		margin-top: -4px;
	}
}

.v-data-table {
	&.candidates-table {
		border-radius: 0;

		&.theme--light {
			background-color: rgba(242, 245, 245, 0.8);
			border-bottom: thin solid rgba(0, 0, 0, 0.12);

			tbody {
				tr {
					td {
						border-bottom: thin solid rgba(0, 0, 0, 0.12);
					}
				}
			}

			tbody.detailed {
				tr {
					&.first-row {
						& > td {
							border-bottom: thin solid rgba(0, 0, 0, 0.03) !important;
						}
					}
					&.second-row {
						& > td {
							border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
						}
					}
				}

				&:hover {
					tr {
						background-color: #eeeeee;
					}
				}
			}

			tbody.checked {
				tr {
					background: #c2dbff !important;
				}
			}
		}

		&.theme--dark {
			border-bottom: thin solid rgba(255, 255, 255, 0.12);

			tbody.simple {
				tr {
					td {
						border-bottom: thin solid rgba(255, 255, 255, 0.12);
					}
				}
			}

			tbody.detailed {
				tr {
					&.first-row {
						& > td {
							border-bottom: thin solid rgba(255, 255, 255, 0.03) !important;
						}
					}
					&.second-row {
						& > td {
							border-bottom: thin solid rgba(255, 255, 255, 0.12) !important;
						}
					}
				}

				&:hover {
					tr {
						background-color: #616161;
					}
				}
			}

			tbody.checked {
				tr {
					background: #184ea6 !important;
				}
			}
		}
	}
}

.v-list.v-list-paddingless {
	padding-top: 0;
	padding-bottom: 0;
}
</style>
