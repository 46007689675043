<template>
	<v-app-bar
		:clipped-left="$vuetify.breakpoint.lgAndUp"
		app
		:color="`${isDark ? '#121212' : 'white'}`"
		flat
		class="talentics-app-bar"
	>
		<v-btn text icon @click.stop="onClickToggleDrawer">
			<v-icon>menu</v-icon>
		</v-btn>

		<v-menu offset-y>
			<template v-slot:activator="{ on }">
				<v-btn
					v-on="on"
					x-large
					outlined
					text
					style="padding-left: 1rem; padding-right: 0.5rem"
				>
					<v-avatar size="20px" item tile style="margin-right: 8px">
						<v-img
							:src="`${
								isDark ? '/talentics-icon.png' : '/talentics-icon-colorful.png'
							}`"
						>
						</v-img>
					</v-avatar>
					{{ $t(`text.${currentRouteFirstLevel}`) }}
					<v-icon>arrow_drop_down</v-icon>
				</v-btn>
			</template>
			<v-list>
				<v-list-item
					v-for="(item, index) in items.filter(
						item => userRole >= item.minRole,
					)"
					:key="index"
					:to="`/${currentLang}/${item.route}`"
				>
					<v-list-item-title>{{ $t(`text.${item.title}`) }}</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<Autocomplete
			:label="$t('label.search-candidates-jobs')"
			:filled="true"
			:dense="false"
			:flat="true"
			:solo="true"
			:solo-inverted="false"
			:hide-details="true"
			:items="autocompleteItems"
			:onKeyUp="handleAutocompleteInput"
			prepend-inner-icon="search"
			class-name="header-autocomplete"
			@change="handleAutocompleteChange"
			:backgroundColor="isDark ? '#515252' : '#f1f3f4'"
		></Autocomplete>

		<v-spacer></v-spacer>
		<MyDrafts></MyDrafts>
		<UserApprovalRequests></UserApprovalRequests>
		<Mentions></Mentions>
		<Notifications></Notifications>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-btn icon @click="handleClickToggleDrawerCalendar" v-on="on">
					<v-icon>today</v-icon>
				</v-btn>
			</template>
			{{ $t('tooltip.calendar') }}
		</v-tooltip>

		<v-menu
			v-model="userMenu"
			offset-y
			bottom
			left
			:close-on-content-click="false"
			eager
			v-if="isMounted"
		>
			<template v-slot:activator="{ on }">
				<v-btn
					x-large
					outlined
					text
					v-on="on"
					style="padding-left: 1rem; padding-right: 1rem"
				>
					<span style="margin-right: 0.5rem">{{ company.name }}</span>
					<v-avatar
						v-if="currentUser.name"
						size="40px"
						item
						right
						color="primary"
					>
						<v-img
							v-if="currentUser.picture"
							:src="currentUser.picture"
							:alt="currentUser.name"
						></v-img>
						<span v-else class="white--text headline">{{
							currentUser.name[0]
						}}</span>
					</v-avatar>
				</v-btn>
			</template>

			<div style="max-height: 600px; overflow-y: auto">
				<v-list>
					<v-list-item-group>
						<template>
							<v-list-item inactive>
								<v-list-item-avatar color="primary" v-if="currentUser.name">
									<img
										v-if="currentUser.picture"
										:src="currentUser.picture"
										:alt="currentUser.name"
									/>
									<span v-else class="white--text headline">{{
										currentUser.name[0]
									}}</span>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title
										><span v-if="currentUser.name">{{ currentUser.name }}</span
										><span v-else>-</span></v-list-item-title
									>
									<v-list-item-subtitle>{{
										currentUser.email
									}}</v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-action>
									<v-btn icon @click="handleGoToSettings">
										<v-icon>settings</v-icon>
									</v-btn>
								</v-list-item-action>
							</v-list-item>
							<v-divider></v-divider>
						</template>
					</v-list-item-group>
				</v-list>

				<v-card
					v-if="currentUser.is_agent"
					tile
					elevation="0"
					color="pink darken-2"
					dark
					style="margin-top: -10px"
				>
					<v-card-title>
						<v-icon>support_agent</v-icon> Support Agent
					</v-card-title>
					<v-card-text>
						<AgentSwitchAccount :company-id="company.id"></AgentSwitchAccount>
					</v-card-text>
				</v-card>

				<v-list>
					<v-list-item-group
						v-if="
							(currentUser.companies && currentUser.companies.length > 1) ||
							currentUser.is_agent
						"
					>
						<template v-for="{ name, id } in companies">
							<v-list-item
								:key="id"
								@click="handleSwitchAccount({ company_id: id })"
							>
								{{ name }}
							</v-list-item>
							<v-divider :key="`divider_${id}`"></v-divider>
						</template>
					</v-list-item-group>

					<v-list-item @click="signOut">
						<v-list-item-title> {{ $t('text.sign-out') }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</div>
		</v-menu>

		<v-progress-linear
			:active="isLoading"
			indeterminate
			color="primary lighten-2"
			absolute
		></v-progress-linear>
	</v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import Autocomplete from '@/components/shared/autocomplete/Autocomplete';
import api from '@/api';
import Notifications from './notifications/Notifications';
import Mentions from './mentions/Mentions';
import UserApprovalRequests from './userApprovalRequests/UserApprovalRequests';
import MyDrafts from './myDrafts/MyDrafts';
import AgentSwitchAccount from './agentSwitchAccount/AgentSwitchAccount';

export default {
	props: {
		onClickToggleDrawer: {
			type: Function,
			required: true,
		},
	},
	components: {
		Autocomplete,
		Notifications,
		Mentions,
		UserApprovalRequests,
		MyDrafts,
		AgentSwitchAccount,
	},
	computed: {
		...mapState({
			currentUser: state => state.currentUser.currentUser,
			userRole: state => state.currentUser.userRole,
			companies: state =>
				state.currentUser.currentUser.companies.sort((a, b) =>
					a.name > b.name ? 1 : -1,
				),
			currentLang: state => state.currentUser.lang,
			routeFirstLevel: state => state.currentRoute.routeFirstLevel,
			isLoading: state => state.apiTraffic.isLoading,
			company: state => state.company.company,
			isDark: state => state.currentUser.isDark,
			drawerCalendar: state => state.currentRoute.drawerCalendar,
		}),
		currentRouteFirstLevel() {
			const firstLevelRoutes = {
				dashboard: 'dashboard',
				settings: 'settings',
				jobs: 'jobs',
				calendar: 'calendar',
				candidates: 'candidates',
				reports: 'reports',
			};
			const { routeFirstLevel } = this;
			return firstLevelRoutes[routeFirstLevel];
		},
	},
	data() {
		return {
			isMounted: false,
			items: [
				{
					title: 'jobs',
					route: 'jobs',
					minRole: 444,
				},
				{
					title: 'candidates',
					route: 'candidates',
					minRole: 333,
				},
				{
					title: 'reports',
					route: 'reports/at-a-glance',
					minRole: 666,
				},
				{
					title: 'settings',
					route: 'settings/my-account',
					minRole: 333,
				},
			],
			loadingAutoComplete: false,
			autocompleteItems: [],
			userMenu: false,
		};
	},
	methods: {
		signOut() {
			this.$store.dispatch('currentUser/signOut');
			const { currentLang } = this;

			if (this.$route.path !== `/${currentLang}`) {
				this.$router.push(`/${currentLang}`);
			}
		},
		handleAutocompleteInput({ newVal }) {
			if (newVal && newVal.length > 2) {
				api.search.get({ q: newVal }).then(response => {
					const { candidates, jobs } = response.data;
					const autocompleteItems = [
						...(candidates.length > 0 ? [{ header: 'Candidates' }] : []),
						...candidates.map(item => ({
							text: item.name,
							value: item.id,
							group: 'candidates',
						})),
						...(candidates.length > 0 ? [{ divider: true }] : []),
						...(jobs.length > 0 ? [{ header: 'Jobs' }] : []),
						...jobs.map(item => ({
							text: `${item.name} (${item.identifier})`,
							value: item.id,
							group: 'jobs',
						})),
					];
					this.autocompleteItems = autocompleteItems;
				});
			} else {
				this.autocompleteItems = [];
			}
		},
		handleAutocompleteChange(newVal) {
			if (newVal) {
				const { currentLang } = this;
				const { value, group } = newVal;
				const { query } = this.$route;
				this.$router.push({
					path: `/${currentLang}/${group}/edit/${value}`,
					query: {
						...query,
						is_search: 1,
					},
				});

				this.autocompleteItems = [];
			}
		},
		handleSwitchAccount({ company_id }) {
			const { currentLang } = this;

			if (this.$route.path !== `/${currentLang}`) {
				this.$router.push(`/${currentLang}`);
			}

			api.switchAccount.post({ company_id, agent_mode: false }).then(() => {
				window.location.reload();
			});

			this.userMenu = false;
		},
		handleGoToSettings() {
			const { currentLang } = this;
			this.$router.push(`/${currentLang}/settings/my-account`);
			this.userMenu = false;
		},
		handleClickToggleDrawerCalendar() {
			const { drawerCalendar } = this;
			this.$store.commit('currentRoute/setDrawerCalendar', {
				drawerCalendar: !drawerCalendar,
			});
		},
	},
	mounted() {
		this.isMounted = true;
	},
};
</script>

<style lang="scss">
.v-progress-linear--absolute {
	top: 0;
}

.header-autocomplete {
	margin-left: 6rem !important;

	.v-input__slot {
		margin-bottom: 0;
	}
}

.talentics-app-bar {
	&.theme--light {
		.v-toolbar__content {
			border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		}
	}
	&.theme--dark {
		.v-toolbar__content {
			border-bottom: 1px solid rgba(255, 255, 255, 0.12);
		}
	}
}
</style>
