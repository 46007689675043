<template>
	<v-container fluid fill-height style="padding: 0">
		<v-layout>
			<v-flex>
				<div style="padding: 0.5rem; display: flex">
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn
								v-on="on"
								icon
								:color="`${isDark ? 'grey lighten-3' : 'grey darken-3'}`"
								@click="handleRefresh"
								style="margin-right: 0.5rem"
							>
								<v-icon>refresh</v-icon>
							</v-btn>
						</template>
						{{ $t('tooltip.refresh') }}
					</v-tooltip>
				</div>

				<v-divider></v-divider>

				<div style="max-width: 500px; padding-top: 1rem">
					<v-container>
						<v-row>
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.remaining-credits') }}
									</legend>
									<v-container>
										<v-row>
											<v-col>
												<fieldset
													class="talentics-fieldset talentics-fieldset-paddings"
												>
													<legend>
														{{ $t('label.resume-parsing-usage') }}
													</legend>
													{{
														$t('text.x-credits-remaining', {
															totalCredits: resumeParsingTotalCredits,
															remainingCredits:
																resumeParsingTotalCredits -
																resumeParsingUsageTotal,
														})
													}}
												</fieldset>
											</v-col>
										</v-row>
										<v-row>
											<v-col>
												<fieldset
													class="talentics-fieldset talentics-fieldset-paddings"
												>
													<legend>
														{{ $t('label.score-match-usage') }}
													</legend>
													{{
														$t('text.x-credits-remaining', {
															totalCredits: scoreMatchTotalCredits,
															remainingCredits:
																scoreMatchTotalCredits - scoreMatchUsageTotal,
														})
													}}
												</fieldset>
											</v-col>
										</v-row>
									</v-container>
								</fieldset>
							</v-col>
						</v-row>

						<v-row>
							<v-col>
								<fieldset
									class="talentics-fieldset talentics-fieldset-paddings"
								>
									<legend>
										{{ $t('label.usage-information') }} ({{
											$t('label.date-range')
										}})
									</legend>
									<v-container>
										<v-row>
											<v-col>
												<DatePicker
													v-model="datePickerDate"
													@input="handleDatePickerInput"
													no-title
													reactive
													dense
													range
													:label="$t('label.date-range')"
												></DatePicker>
											</v-col>
										</v-row>
										<v-row>
											<v-col>
												<v-divider></v-divider>
											</v-col>
										</v-row>
										<v-row>
											<v-col>
												<fieldset
													class="talentics-fieldset talentics-fieldset-paddings"
												>
													<legend>
														{{ $t('label.resume-parsing-usage') }}
													</legend>
													{{ resumeParsingUsage }}
												</fieldset>
											</v-col>
										</v-row>
										<v-row>
											<v-col>
												<fieldset
													class="talentics-fieldset talentics-fieldset-paddings"
												>
													<legend>
														{{ $t('label.score-match-usage') }}
													</legend>
													{{ scoreMatchUsage }}
												</fieldset>
											</v-col>
										</v-row>
									</v-container>
								</fieldset>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import DatePicker from '@/components/shared/datePicker/DatePicker';
import api from '@/api';

export default {
	name: 'ResourceUsage',
	components: {
		DatePicker,
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
	},
	data() {
		const max_date = new Date();
		max_date.setDate(max_date.getDate() - 1);
		const min_date = new Date(max_date);
		min_date.setDate(min_date.getDate() - 30);

		const max_date_formatted = format(max_date, 'yyyy-MM-dd');
		const min_date_formatted = format(min_date, 'yyyy-MM-dd');

		const datePickerDate = [min_date_formatted, max_date_formatted];

		return {
			format,
			datePickerDate,
			resumeParsingUsage: 0,
			scoreMatchUsage: 0,
			resumeParsingUsageTotal: 0,
			scoreMatchUsageTotal: 0,
			resumeParsingTotalCredits: 0,
			scoreMatchTotalCredits: 0,
		};
	},
	methods: {
		handleRefresh() {
			const { datePickerDate } = this;
			this.handleGetResumeParsingUsage(datePickerDate);
			this.handleGetResumeParsingUsageTotal();
			this.handleGetScoreMatchUsage(datePickerDate);
			this.handleGetScoreMatchUsageTotal();
			this.handleGetCompanyCredits();
		},
		handleDatePickerInput(dates) {
			this.handleGetResumeParsingUsage(dates);
			this.handleGetScoreMatchUsage(dates);
		},
		handleGetResumeParsingUsage(dates) {
			if (dates && dates.length === 2) {
				const [start_date, end_date] = dates;

				let min_date = start_date;
				let max_date = end_date;

				const start_date_formatted = new Date(start_date);
				const end_date_formatted = new Date(end_date);

				if (end_date_formatted < start_date_formatted) {
					min_date = end_date;
					max_date = start_date;
				}

				api.company.resourceUsage.resumeParsing
					.get({ min_date, max_date })
					.then(response => {
						const { count } = response.data;
						this.resumeParsingUsage = count;
					});
			}
		},
		handleGetResumeParsingUsageTotal() {
			api.company.resourceUsage.resumeParsing
				.get({ min_date: null, max_date: null })
				.then(response => {
					const { count } = response.data;
					this.resumeParsingUsageTotal = count;
				});
		},
		handleGetScoreMatchUsage(dates) {
			if (dates && dates.length === 2) {
				const [start_date, end_date] = dates;

				let min_date = start_date;
				let max_date = end_date;

				const start_date_formatted = new Date(start_date);
				const end_date_formatted = new Date(end_date);

				if (end_date_formatted < start_date_formatted) {
					min_date = end_date;
					max_date = start_date;
				}

				api.company.resourceUsage.scoreMatch
					.get({ min_date, max_date })
					.then(response => {
						const { count } = response.data;
						this.scoreMatchUsage = count;
					});
			}
		},
		handleGetScoreMatchUsageTotal() {
			api.company.resourceUsage.scoreMatch
				.get({ min_date: null, max_date: null })
				.then(response => {
					const { count } = response.data;
					this.scoreMatchUsageTotal = count;
				});
		},
		handleGetCompanyCredits() {
			api.company.resourceUsage.companyCredits.get().then(response => {
				const { resume_parsing, score_match } = response.data;
				this.resumeParsingTotalCredits = resume_parsing;
				this.scoreMatchTotalCredits = score_match;
			});
		},
	},
	mounted() {
		const { datePickerDate } = this;
		this.handleGetResumeParsingUsage(datePickerDate);
		this.handleGetResumeParsingUsageTotal();
		this.handleGetScoreMatchUsage(datePickerDate);
		this.handleGetScoreMatchUsageTotal();
		this.handleGetCompanyCredits();
	},
};
</script>
