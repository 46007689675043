<template>
	<div>
		<ValidationObserver
			ref="schedule_candidate_form"
			tag="form"
			@submit="handleSchedule"
			id="schedule_candidate_form"
		>
			<v-dialog
				scrollable
				transition="dialog-bottom-transition"
				:value="isVisible"
				max-width="1000"
				@click:outside="handleClose"
				@keydown.esc="handleClose"
				persistent
				:fullscreen="fullscreen"
			>
				<v-card>
					<v-card-title class="pa-0">
						<v-toolbar :color="`${isDark ? '' : 'grey lighten-3'}`" flat dense>
							<v-btn v-if="fullscreen" @click="onClose" icon small>
								<v-icon>close</v-icon>
							</v-btn>
							<v-toolbar-title>
								{{ $t('text.schedule') }}: {{ candidate.name }}
							</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-btn small @click="handleFullScreenClick">
								<v-icon left v-if="fullscreen">close_fullscreen</v-icon>
								<v-icon left v-else>open_in_full</v-icon>
								{{ $t('button.full-screen') }}
							</v-btn>
						</v-toolbar>
					</v-card-title>

					<v-divider></v-divider>

					<v-card-text
						style="padding: 0"
						ref="scheduleCandidateDialogContent"
						v-if="step === 0"
					>
						<v-container>
							<v-row>
								<v-col>
									<ApplicationPicker
										v-model="application_id"
										:applications="candidate.applications"
										:label="`${$t('label.application')}*`"
										rules="required"
										dense
									></ApplicationPicker>
								</v-col>

								<v-col>
									<DatePicker
										v-model="datePickerDate"
										full-width
										no-title
										reactive
										dense
										prepend-inner-icon="event"
										:label="`${$t('label.date')}*`"
									></DatePicker>
								</v-col>

								<v-col align-self="center">
									<UserPicker
										v-model="interviewers"
										:users="users.filter(user => user.active)"
										:label="$t('label.interviewers')"
										multiple
										dense
									></UserPicker>

									<div
										style="margin-top: 0.5rem"
										v-if="interviewers.length === 0"
									>
										<v-alert icon="arrow_circle_up" prominent text type="info">
											{{ $t('text.start-by-adding-interviewers') }}
										</v-alert>
									</div>
								</v-col>
							</v-row>

							<v-row v-if="interviewers.length > 0">
								<v-col>
									<div style="border: 1px solid grey">
										<div class="interview-calendar-user-names">
											<div
												v-for="(interviewerId, index) in interviewers"
												:key="`name_${index}`"
												:style="`width: ${interviewerCalendarWidth};`"
											>
												<v-toolbar
													tile
													dense
													elevation="1"
													:style="`margin-left: ${index === 0 ? 62 : 0}px`"
												>
													<v-toolbar-title>
														{{
															handleGetUserName({
																userId: interviewerId,
															})
														}}
													</v-toolbar-title>
												</v-toolbar>
											</div>
										</div>
										<v-divider></v-divider>
										<div
											class="interview-calendar-container"
											ref="interviewCalendarContainer"
										>
											<div class="interview-calendar-fluid">
												<div
													v-for="(interviewerId, index) in interviewers"
													:key="index"
													:style="`width: ${interviewerCalendarWidth}`"
												>
													<InterviewerCalendar
														:interviewer="
															users.find(item => item.id === interviewerId)
														"
														:newEvents="newEvents"
														:startDate="datePickerDate"
														:interviewerIndex="index"
														:onMouseDown="
															params =>
																handleMouseDown({
																	...params,
																	interviewerId,
																})
														"
														:onEventModify="handleNewEventModify"
													></InterviewerCalendar>
												</div>
											</div>
										</div>
									</div>

									<div
										style="margin-top: 0.5rem"
										v-if="interviewers.length > 0 && newEvents.length === 0"
									>
										<v-alert icon="arrow_circle_up" prominent text type="info">
											{{ $t('text.click-on-the-desired-time-slot') }}
										</v-alert>
									</div>
								</v-col>
							</v-row>

							<v-row v-if="newEvents.length > 0">
								<v-col>
									<v-divider></v-divider>
								</v-col>
							</v-row>

							<NewEvents
								v-model="newEvents"
								:job_id="job_id"
								:candidate="candidate"
								:onDeleteNewEvent="handleDeleteNewEvent"
							></NewEvents>
						</v-container>
					</v-card-text>
					<v-card-text
						style="padding: 0"
						ref="scheduleCandidateDialogContentPreview"
						v-if="step === 1"
					>
						<v-container>
							<v-row>
								<v-col
									v-for="(newEvent, newEventIndex) in newEvents"
									:key="`newEvent_${newEventIndex}`"
									cols="12"
								>
									<EventOrganizer
										:event="newEvent"
										:candidate="candidate"
										:isPreviewMode="isPreviewMode"
										:jobId="job_id"
										:ref="`eventOrganizer_${newEventIndex}`"
									></EventOrganizer>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn text @click="onClose" v-if="!fullscreen">
							<v-icon left>close</v-icon>
							{{ $t('button.discard') }}
						</v-btn>
						<v-btn
							v-if="fullscreen && step === 0"
							@click="handleGoToEventDetails"
							color="primary"
							:disabled="newEvents.length === 0"
							style="margin-left: 0.25rem"
						>
							<v-icon left>event</v-icon>
							{{ $t('button.event-details') }}
							<v-icon right>arrow_forward</v-icon>
						</v-btn>
						<v-btn
							v-if="step === 1 && !isPreviewMode"
							@click="handleGoToFirstStep"
						>
							<v-icon left>arrow_back</v-icon>
							{{ $t('button.back') }}
						</v-btn>
						<v-btn
							v-if="step === 1 && isPreviewMode"
							@click="handleGoToPreview"
							:disabled="newEvents.length === 0"
						>
							<v-icon left>arrow_back</v-icon>
							{{ $t('button.event-details') }}
						</v-btn>
						<v-btn
							v-if="step === 1 && !isPreviewMode"
							@click="handleGoToPreview"
							color="primary"
							:disabled="newEvents.length === 0"
						>
							<v-icon left>preview</v-icon>
							{{ $t('button.preview') }}
							<v-icon right>arrow_forward</v-icon>
						</v-btn>
						<v-btn
							v-if="step === 1 && isPreviewMode"
							@click="handleSchedule"
							color="primary"
							:disabled="newEvents.length === 0"
						>
							<v-icon left>event</v-icon>
							{{ $t('button.schedule') }}
							<v-icon right>arrow_forward</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</ValidationObserver>
	</div>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import api from '@/api';
import InterviewerCalendar from './interviewerCalendar/InterviewerCalendar';
import NewEvents from './newEvents/NewEvents';
import DatePicker from '@/components/shared/datePicker/DatePicker';
import UserPicker from '@/components/shared/userPicker/UserPicker';
import ApplicationPicker from '@/components/shared/applicationPicker/ApplicationPicker';
import EventOrganizer from '@/components/shared/eventOrganizer/EventOrganizer';

export default {
	name: 'ScheduleCandidateDialog',
	props: {
		candidateId: {
			type: [Number, String],
			required: true,
		},
		onClose: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
		onSuccess: {
			type: Function,
			required: false,
			default() {
				return false;
			},
		},
	},
	components: {
		ValidationObserver,
		InterviewerCalendar,
		NewEvents,
		DatePicker,
		UserPicker,
		ApplicationPicker,
		EventOrganizer,
	},
	computed: {
		...mapState({
			users: state => state.users.items,
			jobs: state => state.jobs.items,
			isDark: state => state.currentUser.isDark,
			currentUser: state => state.currentUser.currentUser,
			provider: state => state.currentUser.provider,
		}),
	},
	data() {
		const datePickerDate = format(new Date(), 'yyyy-MM-dd');
		return {
			format,
			datePickerDate,
			interviewers: [],
			candidate: {
				applications: [],
			},
			interviewerCalendarWidth: '100%',
			viewAvailability: false,
			job_id: null,
			application_id: null,
			isVisible: false,
			newEvents: [],
			fullscreen: true,
			step: 0,
			eventColors: ['blue', 'red', 'green', 'purple', 'pink'],
			isPreviewMode: false,
		};
	},
	methods: {
		getCandidate({ id }) {
			this.$store
				.dispatch('candidates/get', { id, refresh: true })
				.then(response => {
					const { items } = response.data;
					if (items.length > 0) {
						this.candidate = items[0];
						const { applications } = items[0];
						if (applications && applications.length > 0) {
							this.application_id = applications[0].id;
						}
					}
				});
		},
		handleToggleViewAvailability() {
			this.viewAvailability = !this.viewAvailability;
		},
		handleMouseDown({ date }) {
			const startDate = new Date(date);
			const start = format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss');
			const endDate = new Date(date);
			endDate.setTime(endDate.getTime() + 30 * 60000);
			const end = format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss');
			const { job_id, jobs } = this;
			const job = jobs.find(item => item.id === job_id);
			const rand = Math.floor(100000 + Math.random() * 9000000);
			const mainColor = this.eventColors[this.newEvents.length % 5];

			this.newEvents = [
				...this.newEvents,
				{
					name: `New Interview / ${job ? job.name : ''} / ${this.candidate.name}`,
					description: '',
					descriptionEditable: '',
					date: format(new Date(date), 'yyyy-MM-dd'),
					start,
					end,
					interviewers: [...this.interviewers],
					mainColor,
					color: `${mainColor} darken-1`,
					event_type_id: null,
					visibility: 'default',
					notifyCandidate: true,
					googleMeet: true,
					length: 30,
					uuid: `new_event_${rand}`,
				},
			];
			setTimeout(
				function () {
					const to = this.$refs.scheduleCandidateDialogContent.scrollHeight;
					this.$refs.scheduleCandidateDialogContent.scrollTop = to;
				}.bind(this),
				200,
			);
		},
		async handleSchedule() {
			const isValid = await this.$refs.schedule_candidate_form.validate();

			if (!isValid) {
				return false;
			}

			const items = this.newEvents.map((newEventItem, newEventItemIndex) => {
				const item =
					this.$refs[
						`eventOrganizer_${newEventItemIndex}`
					][0].handeExportItem();

				const {
					name,
					description,
					start,
					end,
					interviewers,
					length,
					event_type_id,
					visibility,
					notifyCandidate,
					googleMeet,
					attachments,
				} = item;

				const startDate = new Date(start);
				const endDate = new Date(end);
				const { candidate } = this;
				return {
					summary: name,
					location: '',
					description,
					start: {
						dateTime: startDate.toISOString(),
						timeZone: 'Europe/Istanbul',
					},
					end: {
						dateTime: endDate.toISOString(),
						timeZone: 'Europe/Istanbul',
					},
					attendees: [
						...this.users
							.filter(({ id }) => interviewers.indexOf(id) > -1)
							.map(({ email }) => ({ email })),
						...(notifyCandidate && candidate.email.length > 0
							? [{ email: candidate.email[0] }]
							: []),
					],
					reminders: {
						useDefault: true,
					},
					interviewers,
					length,
					event_type_id,
					visibility,
					googleMeet,
					attachments,
				};
			});

			const { job_id, application_id, candidate, currentUser } = this;

			api.events
				.post({
					items,
					job_id,
					application_id,
					candidate_id: candidate.id,
					organizer_id: currentUser.id,
				})
				.then(() => {
					this.onSuccess();
				});
		},
		handleDeleteNewEvent({ index }) {
			this.newEvents = [
				...this.newEvents.filter((item, itemIndex) => index != itemIndex),
			];
		},
		handleClose() {
			return false;
		},
		handleFullScreenClick() {
			this.fullscreen = !this.fullscreen;
		},
		async handleGoToEventDetails() {
			const isValid = await this.$refs.schedule_candidate_form.validate();
			if (!isValid) return false;
			this.step = 1;
		},
		handleGoToFirstStep() {
			this.step = 0;
			this.handleSetCalendarScrollTop();
		},
		handleGetUserName({ userId }) {
			const { users } = this;
			const user = users.find(item => item.id === userId);
			if (user) {
				const { name, email } = user;
				if (name) {
					return name;
				}
				return email;
			}
			return '-';
		},
		handleSetCalendarScrollTop() {
			setTimeout(
				function () {
					this.$refs.interviewCalendarContainer.scrollTop = 400;
				}.bind(this),
				250,
			);
		},
		handleNewEventModify({ uuid, newStart, newEnd }) {
			this.newEvents = this.newEvents.map(newEvent => {
				if (newEvent.uuid === uuid) {
					const startDate = new Date(newStart);
					const endDate = new Date(newEnd);
					const duration = (endDate - startDate) / 60000;
					return {
						...newEvent,
						start: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
						end: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
						length: duration,
					};
				}
				return newEvent;
			});
		},
		handleGoToPreview() {
			this.isPreviewMode = !this.isPreviewMode;
		},
	},
	created() {
		this.$store.dispatch('eventTypes/get', {
			refresh: true,
			updateStore: true,
		});
		this.$store.dispatch('emailTemplates/get', { updateStore: true });
	},
	mounted() {
		const { candidateId } = this;
		this.getCandidate({ id: candidateId });
		setTimeout(
			function () {
				this.isVisible = true;
			}.bind(this),
			250,
		);
	},
	watch: {
		application_id: {
			handler: function (newVal) {
				const { applications } = this.candidate;
				if (newVal && applications.length > 0) {
					const application = applications.find(item => item.id === newVal);
					if (!application) return false;
					const { job_id } = application;
					this.job_id = job_id;
				}
			},
			deep: true,
			immediate: true,
		},
		interviewers: {
			handler: function (newVal, oldVal) {
				if (newVal && oldVal && newVal.length === 1 && oldVal.length === 0) {
					this.handleSetCalendarScrollTop();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style lang="scss">
.interview-calendar-container {
	max-height: 400px;
	overflow-y: auto;
	overflow-x: auto;

	.interview-calendar-fluid {
		display: flex;
		flex-wrap: nowrap;
	}
}

.interview-calendar-user-names {
	display: flex;
	flex-wrap: nowrap;
}
</style>
